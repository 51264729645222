import React from 'react';
import PropTypes from 'prop-types';

import './InputStyles.css';

function Input({
		value = '',
		placeholder = '',
		type = 'text',
		onChange = () => {},
		onBlur = () => {},
		error = '',
		classes = ''
	}) {

	return (
		<div className="cp-input-container">
			<div className={"form-group cp-input-wrapper " + classes}>
				<input type={type} className="form-control cp-input" placeholder={placeholder} onChange={onChange} onBlur={onBlur} value={value} />
			</div>
			{error ? <span className="cp-input-error">{error}</span> : null}
		</div>
	);
}

Input.propTypes = {
	value: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	classes: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
};

export default Input;
