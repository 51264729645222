/* Auth */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

/* Get Records */
export const GET_RECORDS_START = 'GET_RECORDS_START';
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS';
export const GET_RECORDS_FAIL = 'GET_RECORDS_FAIL';

/* Set Viewed Records */
export const SET_VIEWED_REQUEST_START = 'SET_VIEWED_REQUEST_START';
export const SET_VIEWED_REQUEST_SUCCESS = 'SET_VIEWED_REQUEST_SUCCESS';
export const SET_VIEWED_REQUEST_FAIL = 'SET_VIEWED_REQUEST_FAIL';

/* Set Contacted Records */
export const SET_CONTACTED_REQUEST_START = 'SET_CONTACTED_REQUEST_START';
export const SET_CONTACTED_REQUEST_SUCCESS = 'SET_CONTACTED_REQUEST_SUCCESS';
export const SET_CONTACTED_REQUEST_FAIL = 'SET_CONTACTED_REQUEST_FAIL';

/* Get Campus Roles */
export const GET_CAMPUS_ROLES_START = 'GET_CAMPUS_ROLES_START';
export const GET_CAMPUS_ROLES_SUCCESS = 'GET_CAMPUS_ROLES_SUCCESS';
export const GET_CAMPUS_ROLES_FAIL = 'GET_CAMPUS_ROLES_FAIL';

/* Send Support Request */
export const SEND_SUPPORT_REQUEST_START = 'SEND_SUPPORT_REQUEST_START';
export const SEND_SUPPORT_REQUEST_SUCCESS = 'SEND_SUPPORT_REQUEST_SUCCESS';
export const SEND_SUPPORT_REQUEST_FAIL = 'SEND_SUPPORT_REQUEST_FAIL';

/* Send Contact Request */
export const SEND_CONTACT_REQUEST_START = 'SEND_CONTACT_REQUEST_START';
export const SEND_CONTACT_REQUEST_SUCCESS = 'SEND_CONTACT_REQUEST_SUCCESS';
export const SEND_CONTACT_REQUEST_FAIL = 'SEND_CONTACT_REQUEST_FAIL';

/* Send Quote Request */
export const SEND_QUOTE_REQUEST_START = 'SEND_QUOTE_REQUEST_START';
export const SEND_QUOTE_REQUEST_SUCCESS = 'SEND_QUOTE_REQUEST_SUCCESS';
export const SEND_QUOTE_REQUEST_FAIL = 'SEND_QUOTE_REQUEST_FAIL';

/* Send Demo Request */
export const SEND_DEMO_REQUEST_START = 'SEND_DEMO_REQUEST_START';
export const SEND_DEMO_REQUEST_SUCCESS = 'SEND_DEMO_REQUEST_SUCCESS';
export const SEND_DEMO_REQUEST_FAIL = 'SEND_DEMO_REQUEST_FAIL';

/* Export Records */
export const EXPORT_RECORDS_START = 'EXPORT_RECORDS_START';
export const EXPORT_RECORDS_SUCCESS = 'EXPORT_RECORDS_SUCCESS';
export const EXPORT_RECORDS_FAIL = 'EXPORT_RECORDS_FAIL';