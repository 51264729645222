import API from '../../services/api/api';
import utils from '../../services/utils';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,

	UPDATE_USER_DATA,

	GET_RECORDS_START,
	GET_RECORDS_SUCCESS,
	GET_RECORDS_FAIL,

	SET_VIEWED_REQUEST_START,
	SET_VIEWED_REQUEST_SUCCESS,
	SET_VIEWED_REQUEST_FAIL,

	SET_CONTACTED_REQUEST_START,
	SET_CONTACTED_REQUEST_SUCCESS,
	SET_CONTACTED_REQUEST_FAIL,

	GET_CAMPUS_ROLES_START,
	GET_CAMPUS_ROLES_SUCCESS,
	GET_CAMPUS_ROLES_FAIL,

	SEND_SUPPORT_REQUEST_START,
	SEND_SUPPORT_REQUEST_SUCCESS,
	SEND_SUPPORT_REQUEST_FAIL,

	SEND_CONTACT_REQUEST_START,
	SEND_CONTACT_REQUEST_SUCCESS,
	SEND_CONTACT_REQUEST_FAIL,

	SEND_QUOTE_REQUEST_START,
	SEND_QUOTE_REQUEST_SUCCESS,
	SEND_QUOTE_REQUEST_FAIL,

	SEND_DEMO_REQUEST_START,
	SEND_DEMO_REQUEST_SUCCESS,
	SEND_DEMO_REQUEST_FAIL,

	EXPORT_RECORDS_START,
	EXPORT_RECORDS_SUCCESS,
	EXPORT_RECORDS_FAIL
} from './actionTypes';

const toastConfig = {
	position: toast.POSITION.BOTTOM_RIGHT,
	hideProgressBar: true,
	autoClose: 5000
};

/* auth */
const loginStart = () => {
	return {
		type: LOGIN_START
	};
};

const loginSuccess = user => {
	return {
		type: LOGIN_SUCCESS,
		user
	};
};

const loginFail = () => {
	return {
		type: LOGIN_FAIL
	};
};

const updateUserData = user => {
	return {
		type: UPDATE_USER_DATA,
		user
	};
};

export const login = authData => {
	return (dispatch) => {
		dispatch(loginStart());

		API.login(authData)
			.then((response) => {

				if (response.status === 200 && response.data && response.data.auth_token) {
					const userData = response.data;
					userData.expires_at = moment().add((userData.expires_in.totalSeconds - 10), 'seconds').valueOf();

					dispatch(loginSuccess(userData));
					utils.setDataToStorage('CP_USER_DATA', {...userData});
					checkTokenExpiration(dispatch, {...userData} );
					toast.success('You have successfully signed in', toastConfig);
				} else {
					dispatch(loginFail({}));
					toast.error('Something went wrong, please try again later.', toastConfig);
				}
			})
			.catch(error => {
				dispatch(loginFail({}));

				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && resp.data.title) {
					message = resp.data.title;
				}
				if(error.response && error.response.status >= 500) {
					message = 'Not available to public users';
				}
				toast.error(message, toastConfig);
				console.log('ERROR', error);
			});
	};
};

export const updateUser = userData => {
	return (dispatch) => {
		//console.log(userData.expires_at - moment().valueOf());
		if(userData.expires_at - moment().valueOf() > 0) {
			dispatch(updateUserData(userData));
			checkTokenExpiration(dispatch, userData);
		} else {
			dispatch(updateUserData({}));
			utils.deleteDataFromStorage('CP_USER_DATA');
			toast.warning('Your session expired. Please login back again', toastConfig);
		}
	}
};

const checkTokenExpiration = (dispatch, user) => {
	setTimeout(() => {
		dispatch(updateUserData({}));
		utils.deleteDataFromStorage('CP_USER_DATA');
		toast.error('Your session expired. Please login again', toastConfig);
	}, (user.expires_at - moment().valueOf()));
};
/* auth end */

/* Get Records */
const getRecordsStart = () => {
	return {
		type: GET_RECORDS_START
	};
};

export const getRecordsSuccess = records => {
	return {
		type: GET_RECORDS_SUCCESS,
		records
	};
};

export const getRecordsFail = () => {
	return {
		type: GET_RECORDS_FAIL
	};
};

export const getRecords = () => {
	return (dispatch, getState) => {
		const userData = getState().main.user;

		dispatch(getRecordsStart());
		API.getRecords(userData.auth_token)
			.then(response => {
				const records = response.data;
				dispatch(getRecordsSuccess(records));
			})
			.catch( error => {
				dispatch(getRecordsFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), toastConfig);
					utils.deleteDataFromStorage('CP_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				}
			});

	};
};
/* Get Requests All End */

/* Set Viewed Requests */
const setViewedRequestsStart = () => {
	return {
		type: SET_VIEWED_REQUEST_START
	};
};

export const setViewedRequestsSuccess = () => {
	return {
		type: SET_VIEWED_REQUEST_SUCCESS
	};
};

export const setViewedRequestsFail = () => {
	return {
		type: SET_VIEWED_REQUEST_FAIL
	};
};

export const setViewedRequests = (data, requestName) => {
	return (dispatch, getState) => {
		const userData = getState().main.user;
		const records = getState().main.records;

		dispatch(setViewedRequestsStart());
		return API.setViewedRequests(userData.auth_token, data)
				.then(response => {
					if (response.data === true) {
						const _records = {...records};
						const _r = [..._records[requestName]];
						const _viewedIds = data.map(el => el.RequestID);
						_records[requestName] = _r.map(i => {
							const _i = {...i};
							if(_viewedIds.indexOf(i.id) > -1) {
								_i.viewDate = moment().format('YYYY-MM-DD');
								_i.justViewed = true;
							}

							return _i;
						});
						dispatch(getRecordsSuccess(_records));
						dispatch(setViewedRequestsSuccess());
						return true;
					} else {
						dispatch(setViewedRequestsFail());
						return false;
					}
				})
				.catch( error => {
					dispatch(setViewedRequestsFail());
					console.log(error);
					if(error.response && error.response.status === 401) {
						utils.deleteDataFromStorage('CP_USER_DATA');
						dispatch({
							type: UPDATE_USER_DATA,
							user: {}
						});
					} else {
						toast.error(((error.response && error.response.statusText) || 'Something went wrong'), toastConfig);
					}
					return false;
				});

	};
};
/* Set Viewed Requests End */

/* Set Contacted Requests */
const setContactedRequestsStart = () => {
	return {
		type: SET_CONTACTED_REQUEST_START
	};
};

export const setContactedRequestsSuccess = () => {
	return {
		type: SET_CONTACTED_REQUEST_SUCCESS
	};
};

export const setContactedRequestsFail = () => {
	return {
		type: SET_CONTACTED_REQUEST_FAIL
	};
};

export const setContactedRequests = (data, requestName) => {
	return (dispatch, getState) => {
		const userData = getState().main.user;
		const records = getState().main.records;

		dispatch(setContactedRequestsStart());
		return API.setContactedRequests(userData.auth_token, data)
			.then(response => {
				if (response.data === true) {
					const _records = {...records};
					const _r = [..._records[requestName]];
					const _contactedIds = data.map(el => el.RequestID);
					_records[requestName] = _r.map(i => {
						const _i = {...i};
						if(_contactedIds.indexOf(i.id) > -1) {
							_i.contactDate = moment().format('YYYY-MM-DD');
						}

						return _i;
					});
					dispatch(getRecordsSuccess(_records));
					dispatch(setContactedRequestsSuccess());
					toast.success('Request successfully marked as contacted', toastConfig);

					return true;
				} else {
					dispatch(setContactedRequestsFail());
					toast.error('Something went wrong. Please try again later', toastConfig);
					return false;
				}
			})
			.catch( error => {
				dispatch(setContactedRequestsFail());
				console.log(error);
				if(error.response && error.response.status === 401) {
					utils.deleteDataFromStorage('CP_USER_DATA');
					dispatch({
						type: UPDATE_USER_DATA,
						user: {}
					});
				} else {
					toast.error(((error.response && error.response.statusText) || 'Something went wrong'), toastConfig);
				}
				return false;
			});

	};
};
/* Set Contacted Requests End */

/* Get Campus Roles */
const getCampusRolesStart = () => {
	return {
		type: GET_CAMPUS_ROLES_START
	};
};

const getCampusRolesSuccess = campusRoles => {
	return {
		type: GET_CAMPUS_ROLES_SUCCESS,
		campusRoles
	};
};

const getCampusRolesFail = () => {
	return {
		type: GET_CAMPUS_ROLES_FAIL
	};
};

export const getCampusRoles = () => {
	return (dispatch) => {
		dispatch(getCampusRolesStart());

		API.getCampusRoles()
			.then((response) => {
				if (response.status === 200) {
					dispatch(getCampusRolesSuccess(response.data));
				} else {
					dispatch(getCampusRolesFail([]));
				}
			})
			.catch(error => {
				dispatch(getCampusRolesFail([]));
				console.log('ERROR', error);
			});
	};
};
/* Get Campus Roles end */

/* Send Support Request */
const sendSupportRequestStart = () => {
	return {
		type: SEND_SUPPORT_REQUEST_START
	};
};

const sendSupportRequestSuccess = () => {
	return {
		type: SEND_SUPPORT_REQUEST_SUCCESS
	};
};

const sendSupportRequestFail = () => {
	return {
		type: SEND_SUPPORT_REQUEST_FAIL
	};
};

export const sendSupportRequest = data => {
	return (dispatch) => {
		dispatch(sendSupportRequestStart());

		return API.sendSupportRequest(data)
			.then((response) => {
				if (response.data === true) {
					dispatch(sendSupportRequestSuccess());
					toast.success('Your request successfully submitted to Control Point team!', toastConfig);
					return true;
				} else {
					dispatch(sendSupportRequestFail());
					toast.error('Something went wrong. Please try again later', toastConfig);
					return false;
				}
			})
			.catch(error => {
				dispatch(sendSupportRequestFail());
				toast.error('Something went wrong. Please try again later', toastConfig);
				console.log('ERROR', error);
				return false;
			});
	};
};
/* Send Support Request end */

/* Send Contact Request */
const sendContactRequestStart = () => {
	return {
		type: SEND_CONTACT_REQUEST_START
	};
};

const sendContactRequestSuccess = () => {
	return {
		type: SEND_CONTACT_REQUEST_SUCCESS
	};
};

const sendContactRequestFail = () => {
	return {
		type: SEND_CONTACT_REQUEST_FAIL
	};
};

export const sendContactRequest = data => {
	return (dispatch) => {
		dispatch(sendContactRequestStart());

		return API.sendContactRequest(data)
			.then((response) => {
				if (response.data === true) {
					dispatch(sendContactRequestSuccess());
					toast.success('Your request successfully submitted to Control Point team!', toastConfig);
					return true;
				} else {
					dispatch(sendContactRequestFail());
					toast.error('Something went wrong. Please try again later', toastConfig);
					return false;
				}
			})
			.catch(error => {
				dispatch(sendContactRequestFail());
				toast.error('Something went wrong. Please try again later', toastConfig);
				console.log('ERROR', error);
				return false;
			});
	};
};
/* Send Contact Request end */

/* Send Quote Request */
const sendQuoteRequestStart = () => {
	return {
		type: SEND_QUOTE_REQUEST_START
	};
};

const sendQuoteRequestSuccess = () => {
	return {
		type: SEND_QUOTE_REQUEST_SUCCESS
	};
};

const sendQuoteRequestFail = () => {
	return {
		type: SEND_QUOTE_REQUEST_FAIL
	};
};

export const sendQuoteRequest = data => {
	return (dispatch) => {
		dispatch(sendQuoteRequestStart());

		return API.sendQuoteRequest(data)
			.then((response) => {
				if (response.data === true) {
					dispatch(sendQuoteRequestSuccess());
					toast.success('Your request successfully submitted to Control Point team!', toastConfig);
					return true;
				} else {
					dispatch(sendQuoteRequestFail());
					toast.error('Something went wrong. Please try again later', toastConfig);
					return false;
				}
			})
			.catch(error => {
				dispatch(sendQuoteRequestFail());
				toast.error('Something went wrong. Please try again later', toastConfig);
				console.log('ERROR', error);
				return false;
			});
	};
};
/* Send Quote Request end */

/* Send Demo Request */
const sendDemoRequestStart = () => {
	return {
		type: SEND_DEMO_REQUEST_START
	};
};

const sendDemoRequestSuccess = () => {
	return {
		type: SEND_DEMO_REQUEST_SUCCESS
	};
};

const sendDemoRequestFail = () => {
	return {
		type: SEND_DEMO_REQUEST_FAIL
	};
};

export const sendDemoRequest = data => {
	return (dispatch) => {
		dispatch(sendDemoRequestStart());

		return API.sendDemoRequest(data)
			.then((response) => {
				if (response.data === true) {
					dispatch(sendDemoRequestSuccess());
					toast.success('Your request successfully submitted to Control Point team!', toastConfig);
					return true;
				} else {
					dispatch(sendDemoRequestFail());
					toast.error('Something went wrong. Please try again later', toastConfig);
					return false;
				}
			})
			.catch(error => {
				dispatch(sendDemoRequestFail());
				toast.error('Something went wrong. Please try again later', toastConfig);
				console.log('ERROR', error);
				return false;
			});
	};
};
/* Send Demo Request end */

/* Export Records */
const exportRecordsStart = () => {
	return {
		type: EXPORT_RECORDS_START
	};
};

const exportRecordsSuccess = file => {
	return {
		type: EXPORT_RECORDS_SUCCESS,
		file
	};
};

const exportRecordsFail = () => {
	return {
		type: EXPORT_RECORDS_FAIL
	};
};

export const exportRecords = date => {
	return (dispatch, getState) => {
		const userData = getState().main.user;

		dispatch(exportRecordsStart());
		return API.exportRecords(userData.auth_token, date)
			.then((response) => {
				let _blob = null;
				if (response.status === 200) {
					dispatch(exportRecordsSuccess(response.data));
					_blob = response.data;
				} else {
					dispatch(getCampusRolesFail([]));
				}
				return _blob;
			})
			.catch(error => {
				dispatch(exportRecordsFail([]));
				console.log('ERROR', error);
				return null;
			});
	};
};
/* Export Records end */