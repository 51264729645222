import React from 'react';
import { NavLink } from 'react-router-dom';
import map from '../../assets/images/footer-map.png';
import './FooterStyles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin, faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

library.add(fab, faLinkedin, faTwitter, faInstagram, faFacebookF);


function Footer() {

	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-2 social">
						<ul className="footer-social">
							<li className="nav-item">
								<a href="https://www.linkedin.com/company/control-point-dismissal-program/" target="_blank" rel="noopener noreferrer" className="purple" title="Control Point LinkedIn">
									<FontAwesomeIcon icon={["fab", "linkedin"]} />
									<span>LinkedIn</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="https://twitter.com/point_control/" target="_blank" rel="noopener noreferrer" className="purple" title="Control Point Twitter">
									<FontAwesomeIcon icon={["fab", "twitter"]} />
									<span>Twitter</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="https://www.instagram.com/ControlPointDismissal/" target="_blank" rel="noopener noreferrer" className="purple" title="Control Point Instagram">
									<FontAwesomeIcon icon={["fab", "instagram"]} />
									<span>Instagram</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="https://www.facebook.com/Control-Point-Dismissal-Program-1970746496270402" target="_blank" rel="noopener noreferrer" className="purple" title="Control Point Facebook">
									<FontAwesomeIcon icon={["fab", "facebook-f"]} />
									<span>Facebook</span>
								</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-12 col-md-12 col-lg-2 nav">
						<ul className="footer-nav">
							<li className="nav-item">
								<NavLink strict to="/" className="purple">Home</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/product-value" className="purple">Product Value</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/support" className="purple">Support</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/about-us" className="purple">About Us</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/privacy-policy" className="purple">Privacy Policy</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/request-demo" className="purple">Request Demo</NavLink>
							</li>
							<li className="nav-item">
								<NavLink strict to="/contact-us" className="purple">Contact Us</NavLink>
							</li>
						</ul>
					</div>

					<div className="col-sm-12 col-md-12 col-lg-4 contact">
						<div className="details purple">
							<p>P. O. Box 1432</p>
							<p>New Waverly, TX 77358</p>
							<p>denise@thecontrolpoint.com</p>
						</div>
						<p className="purple">&copy; All rights reserved. <NavLink strict to="/privacy-policy" className="purple"><span className="bold">Privacy</span></NavLink></p>
					</div>

					<div className="col-md-4 col-lg-4 map">
						<div className="">
							<img src={map} alt="Map" title="Map" />
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;