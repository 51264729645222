import React, { useState } from 'react';
import SEO from '../../services/SEO';
import { NavLink } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import videohowto from '../../assets/video/Product-How-To.mp4';
import videovalue from '../../assets/video/Product-Value.mp4';
import LogoAnimation from '../../components/LogoAnimation/LogoAnimation';
import boy from '../../assets/images/boy.png';
import medicalCross from '../../assets/images/medical-cross.png';
import clock from '../../assets/images/clock.png';
import parentInCar from '../../assets/images/parent-in-car.png';
import './HomeStyles.css';


const pageProps = {
    title: "Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Control Point is a full-control Dismissal Program for school administrators, parents, teachers and educators | Manage students dismissal and lanes of traffic effectively | Most effective school dismissal process automation application available to the U.S. K-12 education | Social distancing against COVID-19 spreading in school during dismissal every day | Comprehensive student dismissal software for schools, teachers, and parents | Efficient afternoon dismissal solution for school districts | Safe student dismissal app for car rider lines | The control point dismissal",
    url: "https://thecontrolpoint.com",
    robots: "index, follow"
}

function Home() {
    const [isShown, setIsShown] = useState(false);

    const handleProductValueSectionDisplay = event => {
        setIsShown(current => !current);
    };

    return (
        <>
       <SEO pageProps={pageProps} />
    <div className="page-wrapper">
      <section className="home-banner-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 banner-title">
              <div>
                <h1><span className="purple bold">Control</span> <span className="orange bold">Point</span></h1>
                <p>Offers <span className="orange bold">safe</span>, <span className="purple-light bold">secure</span> <br/> and <span className="blue bold">efficient</span> dismissal <br/> every day!</p>
              </div>
              <div className="banner-btns">
                <NavLink strict to="/request-quote" className="cp-btn-link">Request quote</NavLink>
                <NavLink strict to="/request-demo" className="cp-btn-link">Request demo</NavLink>
              </div>
            </div>
            <div className="col-6">
                <LogoAnimation />
            </div>
          </div>
        </div>
      </section>

      <section className="home-dismissal-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="purple">Afternoon Student Dismissal</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="afternoon-video-container">
                <ScrollAnimation className="video-left-circle-wrapper" animateIn='videoBounceInLeft' animateOnce offset={10}>
                  <div className="video-left-circle"></div>
                </ScrollAnimation>
                <ScrollAnimation className="video-right-circle-wrapper" animateIn='bounceInRight' animateOnce offset={10}>
                  <div className="video-right-circle">
                    <span className="child"></span>
                  </div>
                </ScrollAnimation>
                <div className="afternoon-video">
                  <video id="background-video" loop autoPlay muted controls>
                    <source src={videohowto} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-features-section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="purple">Discover the Control Point value</h2>
                </div>
            <div className="col-sm-12 col-md-4">
              <div className="feature-container text-center">
                <div className="feature-image efficient"></div>
                <h3 className="orange">Efficient</h3>
                <p className="purple">Staff members scan each driver's dismissal card. The student's name is instantly visible to the teacher.</p>
              </div>
            </div>

            <div className="col-sm-12 col-md-4">
              <div className="feature-container text-center">
                <div className="feature-image effective"></div>
                <h3 className="orange">Effective</h3>
                <p className="purple">Campus administrators configure Control Point for the lanes of traffic and the number of cars scanned.</p>
              </div>
            </div>

            <div className="col-sm-12 col-md-4">
              <div className="feature-container text-center">
                <div className="feature-image timely"></div>
                <h3 className="orange">Timely</h3>
                <p className="purple">As flights load with student names, teachers send students to their designated dismissal area.</p>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section className="home-dismissal-section">
        <div className="container">
            <div className="row">
                            <div className="col-12">
                                {isShown && (
                                    <div className="afternoon-video-container">
                                        <ScrollAnimation className="video-right-circle-wrapper" animateIn='videoBounceInLeft' animateOnce offset={10}>
                                            <div className="video-left-circle"></div>
                                        </ScrollAnimation>
                                        <ScrollAnimation className="video-left-circle-wrapper" animateIn='bounceInRight' animateOnce offset={10}>
                                            <div className="video-right-circle">
                                                <span className="child"></span>
                                            </div>
                                        </ScrollAnimation>
                                        <div className="afternoon-video">
                                            <video id="background-video" loop autoPlay muted controls>
                                                <source src={videovalue} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>)}
                    <div className="text-center">
                                    <button className="cp-btn-link" onClick={handleProductValueSectionDisplay}>{isShown ? "Show less" : "Learn more" }</button>
                    </div>
                 </div>
            </div>
        </div>
     </section>

    <section className="home-ads-section">
        <div className="container">
          <div className="row">

            <div className="col-12">
              <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
                <div className="ads-container">
                  <img src={clock} alt="Clock" title="Clock" height="45" />
                  <span className="orange big">50%&nbsp;</span>
                  <span className="purple"> reduction in time spent In active student dismissal.</span>
                </div>
              </ScrollAnimation>
            </div>

            <div className="col-12 flip-container">
              <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={500}>
                <div className="ads-container">
                  <img src={boy} alt="Boy Student Control Point" title="Boy Student Control Point" height="45" />
                  <span className="orange big">18s&nbsp;</span>
                  <span className="purple"> average time our schools take to dismiss each student.</span>
                </div>
              </ScrollAnimation>
            </div>

            <div className="col-12 flip-container">
              <ScrollAnimation animateIn='wobble' delay={1000}>
                <div className="ads-container">
                                        <img src={parentInCar} alt="Parent In Car Control Point" title="Parent In Car Control Point" height="45" />
                  <span className="orange big">95%&nbsp;</span>
                  <span className="purple"> parent satisfaction with student safety and reduced wait times.</span>
                </div>
              </ScrollAnimation>
            </div>

            <div className="col-12">
              <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutRight' delay={1500}>
                <div className="ads-container">
                  <img src={medicalCross} alt="Medical Cross" title="Medical Cross" height="45" />
                  <span className="orange big">100%&nbsp;</span>
                  <span className="purple"> schools' ability to follow CDC guidelines for social distancing during dismissal.</span>
                </div>
              </ScrollAnimation>
            </div>

          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Home;
