import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import './CPselectStyles.css';

function CPselect({
		value = '',
		name = 'Select',
		options = [],
		onChange = () => {},
		onBlur = () => {},
		error = '',
		classes = ''
	}) {

	const handleChange = (selectedOption) => {
		onChange({target: {value: selectedOption.value}});
	};

	const _selectedOption = (value || value === 0) ? options.filter(i => i.id === value).map(i => ({value: i.id, label: i.name })) : [''];
	const _value = _selectedOption[0];
	const _options = options.map(i => ({value: i.id, label: i.name }));

	return (
		<div className="form-group cp-select-container">
			<div className={"form-group cp-select-wrapper " + classes}>
				<Select
					value={_value}
					options={_options}
					onChange={handleChange}
					isSearchable={false}
					classNamePrefix="cp-select"
					placeholder="Campus Role"
				/>
			</div>
			{error ? <span className="cp-select-error">{error}</span> : null}
		</div>
	);
}

CPselect.propTypes = {
	value: PropTypes.any,
	name: PropTypes.string,
	options: PropTypes.array,
	error: PropTypes.string,
	classes: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
};

export default CPselect;
