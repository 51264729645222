import React, { Component } from 'react';
import SEO from '../../services/SEO';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sendSupportRequest } from '../../store/actions';
import supportImg from '../../assets/images/support_img.png';
import Input from '../../UI/Input/Input';
import CPselect from '../../UI/CPselect/CPselect';
import Textarea from '../../UI/Textarea/Textarea';
import Button from '../../UI/Button/Button';
import { parseIncompletePhoneNumber } from 'libphonenumber-js'
import {SUPPORT_VALIDATION} from '../../services/validation';
import utils from '../../services/utils';
import './SupportStyles.css';

const pageProps = {
    title: "Support - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Have issues with using Control Point, our support team is here to help parents, teachers, educators and school administration for effective and most comfortable school dismissal every day",
    url: "https://thecontrolpoint.com/support",
    robots: "index, follow"
}

const INITIAL_STATE = {
    fields: {
        CampusRoleId: '',
        FullName: '',
        Email: '',
        Phone: '',
        PerformedSteps: '',
        ActionResults: ''
    },
    fieldsError: {},
    fieldsTouched: {}
};

class Support extends Component {
    state = {
        ...INITIAL_STATE
    };

    componentDidMount() {
        window.scrollTo({top: 0});
    }

    checkButtonStatus = fields => {
        const fieldsArray = Object.keys(fields);
        let isDisabled = false;
        for(let i=0; i< fieldsArray.length; i++) {
            const fieldName = fieldsArray[i];
            if(SUPPORT_VALIDATION[fieldName] && SUPPORT_VALIDATION[fieldName].required && !fields[fieldName]) {
                isDisabled = true;
            }
        }

        return isDisabled;
    };

    handleFieldChange = (e, fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fields = {...fields};
        const _fieldsTouched = {...fieldsTouched};
        let value = e.target.value;
        if(fieldName === 'CampusRoleId') {
            value = +value;
        }
        if(fieldName === 'Phone') {
            value = parseIncompletePhoneNumber(value);
        }
        _fieldsTouched[fieldName] = true;

        this.setState({fields: {..._fields, [fieldName]: value}, fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, {...fields, [fieldName]: value}, _fieldsTouched, fieldsError, null, SUPPORT_VALIDATION, this);
    };

    handleBlur = (fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fieldsTouched = {...fieldsTouched};
        _fieldsTouched[fieldName] = true;

        this.setState({fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, fields, _fieldsTouched, fieldsError, true, SUPPORT_VALIDATION, this);
    };

    handleSubmit = () => {
        const {fields} = this.state;

        this.props.onSendSupportRequest(fields)
            .then(success => {
                if(success) {
                    this.setState({...INITIAL_STATE});
                }
            });
    };

    render() {
        const {fields, fieldsError} = this.state;
        const {campusRoles, sendingSupportRequest} = this.props;
        const btnDisabled = Object.keys(fieldsError).length > 0 || this.checkButtonStatus(fields) || sendingSupportRequest;

        return (
             <>
                <SEO pageProps={pageProps} />
                <div className="page-wrapper">
                <section className="support-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <h1 className="purple">How can we help you today?</h1>
                                <div className="support-form">
                                    <div className="row">
                                        <div className="col-sm-10">
                                            <CPselect onChange={e => this.handleFieldChange(e, 'CampusRoleId')}
                                                    onBlur={() => this.handleBlur('CampusRoleId')}
                                                    options={campusRoles}
                                                    name="Campus Role"
                                                    value={fields.CampusRoleId}
                                                    error={fieldsError.CampusRoleId} />
                                        </div>
                                        <div className="col-sm-10">
                                            <Input type="text"
                                                   onChange={e => this.handleFieldChange(e, 'FullName')}
                                                   onBlur={() => this.handleBlur('FullName')}
                                                   value={fields.FullName}
                                                   error={fieldsError.FullName}
                                                   placeholder="Full Name" />
                                        </div>
                                        <div className="col-sm-10">
                                            <Input type="email"
                                                   onChange={e => this.handleFieldChange(e, 'Email')}
                                                   onBlur={() => this.handleBlur('Email')}
                                                   value={fields.Email}
                                                   error={fieldsError.Email}
                                                   placeholder="Email" />
                                        </div>
                                        <div className="col-sm-10">
                                            <Input type="text"
                                                   onChange={e => this.handleFieldChange(e, 'Phone')}
                                                   onBlur={() => this.handleBlur('Phone')}
                                                   value={fields.Phone}
                                                   error={fieldsError.Phone}
                                                   placeholder="Phone" />
                                        </div>
                                    </div>
                                    <Textarea rows={3}
                                            onChange={e => this.handleFieldChange(e, 'PerformedSteps')}
                                            onBlur={() => this.handleBlur('PerformedSteps')}
                                            value={fields.PerformedSteps}
                                            error={fieldsError.PerformedSteps}
                                            placeholder="What steps have you taken so far?"  />
                                    <Textarea rows={3}
                                            onChange={e => this.handleFieldChange(e, 'ActionResults')}
                                            onBlur={() => this.handleBlur('ActionResults')}
                                            value={fields.ActionResults}
                                            error={fieldsError.ActionResults}
                                            placeholder="What happens?" />
                                    <Button title="Submit" active onClick={this.handleSubmit} disabled={btnDisabled} />
                                </div>
                            </div>

                            <div className="col-md-5 col-sm-12 offset-md-1 offset-sm-0">
                                <div className="support-btns-container">
                                    <NavLink strict to="/request-quote" className="cp-btn-link">Request quote</NavLink>
                                    <br />
                                    <NavLink strict to="/request-demo" className="cp-btn-link">Request demo</NavLink>
                                </div>
                                <div className="support-image-container">
                                    <img src={supportImg} alt="Support" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
             </>
        );
    }
}

const mapStateToProps = state => {
    return {
        campusRoles: state.main.campusRoles,
        sendingSupportRequest: state.main.sendingSupportRequest
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendSupportRequest: data => dispatch(sendSupportRequest(data))
    };
};

Support.propTypes = {
    campusRoles: PropTypes.array,
    sendingSupportRequest: PropTypes.bool,
    onSendSupportRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
