import React  from 'react';
import ReactTooltip from "react-tooltip";
import moment from 'moment';

const utility = {

	setDataToStorage: (key, value) => {
		localStorage[key] = JSON.stringify(value);
	},

	getDataFromStorage: (key) => {
		return localStorage[key] ? JSON.parse(localStorage[key]) : null;
	},

	deleteDataFromStorage: (key) => {
		localStorage.removeItem(key);
	},

	updateObject: (oldObject, updatedProperties) => {
		return {
			...oldObject,
			...updatedProperties
		};
	},

	isEmptyObject: (obj) => {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
				return false;
		}
		return true;
	},

	arrayToObject: (arr, key, value) => {
		const _arr = arr || [];
		const _obj = {};
		for(let i = 0; i < _arr.length; i++) {
			const _item = _arr[i];
			_obj[_item[key || 'id']] = _item[value || 'value'];
		}
		return _obj;
	},

	checkValidationErrors: (fieldName, fields, fieldsTouched, fieldsError, blur, validation, _this) => {
		const _fieldsError = {...fieldsError};
		let errorMessage = '';

		if(fieldsTouched[fieldName]) {
			if(validation[fieldName].required && !fields[fieldName]) {
				errorMessage = (fieldName === 'CampusRoleId') ? 'Please select role.' : 'This field is required.';
			} else {
				errorMessage = '';
				if(fieldName === 'Email') {
					const EMAIL_REGEXP = validation[fieldName].regexp;
					if(!EMAIL_REGEXP.test(fields[fieldName])) {
						errorMessage = 'Please Enter a Valid Email';
					}
				}
			}

			if(errorMessage){
				_fieldsError[fieldName] = errorMessage;
			} else {
				delete _fieldsError[fieldName];
			}
			_this.setState({fieldsError: _fieldsError});
		}
	},

	calculateNotViewedQty: (arr, type, onClick) => {
		let _qty = 0;
		for(let i=0; i<arr.length; i++) {
			if(!arr[i].viewDate) {
				_qty += 1;
			}
		}
		return _qty > 0 ? <span onClick={e => onClick(e, type)}>{_qty}</span> : null;
	},

	filterByDateAndViewed: (records, date, showNotViewed) => {
		const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;

		let supportRequests = records.supportRequests || [];
		let demoRequests = records.demoRequests || [];
		let quoteRequests = records.quoteRequests || [];
		let contactRequests = records.contactRequests || [];

		if(formattedDate) {
			supportRequests = supportRequests.filter(i => (i.creationDate || '').indexOf(formattedDate) > -1);
			demoRequests = demoRequests.filter(i => (i.creationDate || '').indexOf(formattedDate) > -1);
			quoteRequests = quoteRequests.filter(i => (i.creationDate || '').indexOf(formattedDate) > -1);
			contactRequests = contactRequests.filter(i => (i.creationDate || '').indexOf(formattedDate) > -1);
		}
		if(showNotViewed) {
			supportRequests = supportRequests.filter(i => (!i.viewDate || i.justViewed));
			demoRequests = demoRequests.filter(i => (!i.viewDate || i.justViewed));
			quoteRequests = quoteRequests.filter(i => (!i.viewDate || i.justViewed));
			contactRequests = contactRequests.filter(i => (!i.viewDate || i.justViewed));
		}

		return {supportRequests, demoRequests, quoteRequests, contactRequests};
	},

	renderSupportHead: () => {
		return (
			<tr>
				<th></th>
				<th className="rl-no">No.</th>
				<th className="rl-name">Name</th>
				<th className="rl-email">Email</th>
				<th className="rl-campus-role">Campus Role</th>
				<th className="rl-phone">Phone</th>
				<th className="rl-steps">What Steps</th>
				<th className="rl-results">What happens</th>
				<th className="rl-date">Contact Date</th>
				<th className="rl-date">Date</th>
			</tr>
		);
	},

	renderSupportRequests: (sr, cr, chunk = [], contactingRequests = {}, handleChangeCheckbox = () => {}) => {
		return sr.map((i, idx) => {
			const _cr = cr.filter(c => c.id === i.campusRoleId) || [];
			const _date = (i.creationDate || '').split('T')[0];
			const _contactDate = (i.contactDate || '').split('T')[0];
			return (
				<tr key={i.id} className={(chunk.indexOf(idx) === -1 ? "hide" : "") + (i.contactDate ? " contacted " : "") + (i.viewDate ? "" : " new ")}>
					<td>
						<label className="cp-checkbox-container">
							<input type="checkbox"
								   onChange={e => handleChangeCheckbox(e, i.id, i.requestTypeId)}
								   checked={!!contactingRequests[i.id]}
								   disabled={i.contactDate} />
							<span className="cp-checkbox-checkmark"></span>
						</label>
					</td>
					<td className="rl-no">{i.id}</td>
					<td className="rl-name">{i.fullName}</td>
					<td className="rl-email">{i.email}</td>
					<td className="rl-campus-role">{(_cr && _cr[0] && _cr[0].name) || ''}</td>
					<td className="rl-phone">{i.phone}</td>
					<td className="rl-steps"
						data-for={'cp-tooltip-support-performedSteps-' + i.id}
						data-tip={i.performedSteps}
						data-class="cp-tooltip"
						data-iscapture="true">
						<ReactTooltip
							id={'cp-tooltip-support-performedSteps-' + i.id}
							place="top"
							type="light"
							/>
						{i.performedSteps}
					</td>
					<td className="rl-results"
						data-for={'cp-tooltip-support-actionResults-' + i.id}
						data-tip={i.actionResults}
						data-class="cp-tooltip"
						data-iscapture="true">
						<ReactTooltip
							id={'cp-tooltip-support-actionResults-' + i.id}
							place="top"
							type="light"
							/>
						{i.actionResults}
					</td>
					<td className="rl-date">{_contactDate}</td>
					<td className="rl-date">{_date}</td>
				</tr>
			);
		})
	},

	renderDemoHead: () => {
		return (
			<tr>
				<th></th>
				<th className="rl-no">No.</th>
				<th className="rl-name">Name</th>
				<th className="rl-email">Email</th>
				<th className="rl-campus-role">Campus Role</th>
				<th className="rl-school-name">School Name</th>
				<th className="rl-school-address">School Address</th>
				<th className="rl-school-district">School District</th>
				<th className="rl-best-contact-time">Best Contact Time</th>
				<th className="rl-phone">Phone</th>
				<th className="rl-comment">Comment</th>
				<th className="rl-date">Contact Date</th>
				<th className="rl-date">Date</th>
			</tr>
		);
	},

	renderDemoRequests: (sr, cr, chunk = [], contactingRequests = {}, handleChangeCheckbox = () => {}) => {
		return sr.map((i, idx) => {
			const _cr = cr.filter(c => c.id === i.campusRoleId) || [];
			const _date = (i.creationDate || '').split('T')[0];
			const _contactDate = (i.contactDate || '').split('T')[0];
			return (
				<tr key={i.id} className={(chunk.indexOf(idx) === -1 ? "hide" : "") + (i.contactDate ? " contacted " : "") + (i.viewDate ? "" : " new ")}>
					<td>
						<label className="cp-checkbox-container">
							<input type="checkbox"
								   onChange={e => handleChangeCheckbox(e, i.id, i.requestTypeId)}
								   checked={!!contactingRequests[i.id]}
								   disabled={i.contactDate} />
							<span className="cp-checkbox-checkmark"></span>
						</label>
					</td>
					<td className="rl-no">{i.id}</td>
					<td className="rl-name">{i.fullName}</td>
					<td className="rl-email">{i.email}</td>
					<td className="rl-campus-role">{(_cr && _cr[0] && _cr[0].name) || ''}</td>
					<td className="rl-school-name">{i.schoolName}</td>
					<td className="rl-school-address">{i.schoolAddress}</td>
					<td className="rl-school-district">{i.schoolDistrict}</td>
					<td className="rl-best-contact-time">{i.bestContactTime}</td>
					<td className="rl-phone">{i.phone}</td>
					<td className="rl-comment"
						data-for={'cp-tooltip-demo-comment-' + i.id}
						data-tip={i.comment}
						data-class="cp-tooltip"
						data-iscapture="true">
						<ReactTooltip
							id={'cp-tooltip-demo-comment-' + i.id}
							place="top"
							type="light"
							/>
						{i.comment}
					</td>
					<td className="rl-date">{_contactDate}</td>
					<td className="rl-date">{_date}</td>
				</tr>
			);
		})
	},

	renderQuoteHead: () => {
		return (
			<tr>
				<th></th>
				<th className="rl-no">No.</th>
				<th className="rl-name">Name</th>
				<th className="rl-email">Email</th>
				<th className="rl-campus-role">Campus Role</th>
				<th className="rl-school-name">School Name</th>
				<th className="rl-school-address">School Address</th>
				<th className="rl-school-district">School District</th>
				<th className="rl-students-qty">Number of Students</th>
				<th className="rl-faculty-qty">Number of Faculty/Staff</th>
				<th className="rl-phone">Phone</th>
				<th className="rl-comment">Comment</th>
				<th className="rl-date">Contact Date</th>
				<th className="rl-date">Date</th>
			</tr>
		);
	},

	renderQuoteRequests: (sr, cr, chunk = [], contactingRequests = {}, handleChangeCheckbox = () => {}) => {
		return sr.map((i, idx) => {
			const _cr = cr.filter(c => c.id === i.campusRoleId) || [];
			const _date = (i.creationDate || '').split('T')[0];
			const _contactDate = (i.contactDate || '').split('T')[0];
			return (
				<tr key={i.id} className={(chunk.indexOf(idx) === -1 ? "hide" : "") + (i.contactDate ? " contacted " : "") + (i.viewDate ? "" : " new ")}>
					<td>
						<label className="cp-checkbox-container">
							<input type="checkbox"
								   onChange={e => handleChangeCheckbox(e, i.id, i.requestTypeId)}
								   checked={!!contactingRequests[i.id]}
								   disabled={i.contactDate} />
							<span className="cp-checkbox-checkmark"></span>
						</label>
					</td>
					<td className="rl-no">{i.id}</td>
					<td className="rl-name">{i.fullName}</td>
					<td className="rl-email">{i.email}</td>
					<td className="rl-campus-role">{(_cr && _cr[0] && _cr[0].name) || ''}</td>
					<td className="rl-school-name">{i.schoolName}</td>
					<td className="rl-school-address">{i.schoolAddress}</td>
					<td className="rl-school-district">{i.schoolDistrict}</td>
					<td className="rl-students-qty">{i.numberOfStudents}</td>
					<td className="rl-faculty-qty">{i.numberOfFaculty}</td>
					<td className="rl-phone">{i.phone}</td>
					<td className="rl-comment"
						data-for={'cp-tooltip-quote-comment-' + i.id}
						data-tip={i.comment}
						data-class="cp-tooltip"
						data-iscapture="true">
						<ReactTooltip
							id={'cp-tooltip-quote-comment-' + i.id}
							place="top"
							type="light"
							/>
						{i.comment}
					</td>
					<td className="rl-date">{_contactDate}</td>
					<td className="rl-date">{_date}</td>
				</tr>
			);
		})
	},

	renderContactHead: () => {
		return (
			<tr>
				<th></th>
				<th className="rl-no">No.</th>
				<th className="rl-name">Name</th>
				<th className="rl-email">Email</th>
				<th className="rl-campus-role">Campus Role</th>
				<th className="rl-school-name">School Name</th>
				<th className="rl-school-address">School Address</th>
				<th className="rl-school-district">School District</th>
				<th className="rl-phone">Phone</th>
				<th className="rl-comment">Comment</th>
				<th className="rl-date">Contact Date</th>
				<th className="rl-date">Date</th>
			</tr>
		);
	},

	renderContactRequests: (sr, cr, chunk = [], contactingRequests = {}, handleChangeCheckbox = () => {}) => {
		return sr.map((i, idx) => {
			const _cr = cr.filter(c => c.id === i.campusRoleId) || [];
			const _date = (i.creationDate || '').split('T')[0];
			const _contactDate = (i.contactDate || '').split('T')[0];
			return (
				<tr key={i.id} className={(chunk.indexOf(idx) === -1 ? "hide" : "") + (i.contactDate ? " contacted " : "") + (i.viewDate ? "" : " new ")}>
					<td>
						<label className="cp-checkbox-container">
							<input type="checkbox"
								   onChange={e => handleChangeCheckbox(e, i.id, i.requestTypeId)}
								   checked={!!contactingRequests[i.id]}
								   disabled={i.contactDate} />
							<span className="cp-checkbox-checkmark"></span>
						</label>
					</td>
					<td className="rl-no">{i.id}</td>
					<td className="rl-name">{i.fullName}</td>
					<td className="rl-email">{i.email}</td>
					<td className="rl-campus-role">{(_cr && _cr[0] && _cr[0].name) || ''}</td>
					<td className="rl-school-name">{i.schoolName}</td>
					<td className="rl-school-address">{i.schoolAddress}</td>
					<td className="rl-school-district">{i.schoolDistrict}</td>
					<td className="rl-phone">{i.phone}</td>
					<td className="rl-comment"
						data-for={'cp-tooltip-contact-comment-' + i.id}
						data-tip={i.comment}
						data-class="cp-tooltip"
						data-iscapture="true">
						<ReactTooltip
							id={'cp-tooltip-contact-comment-' + i.id}
							place="top"
							type="light"
							/>
						{i.comment}
					</td>
					<td className="rl-date">{_contactDate}</td>
					<td className="rl-date">{_date}</td>
				</tr>
			);
		})
	}
};

export default utility;