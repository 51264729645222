import React, { useEffect, Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { updateUser, getCampusRoles } from './store/actions';
import utils from './services/utils';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './containers/Home/Home';
import AboutUs from './containers/AboutUs/AboutUs';
import Support from './containers/Support/Support';
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './containers/ContactUs/ContactUs';
import RequestQuote from './containers/RequestQuote/RequestQuote';
import RequestDemo from './containers/RequestDemo/RequestDemo';
import ProductValue from './containers/ProductValue/ProductValue';
import Records from './containers/Records/Records';
import Login from './containers/Login/Login';

const App = ({ user, onUpdateUser, onGetCampusRoles }) => {
  const userData = utils.getDataFromStorage('CP_USER_DATA');
  const isAuthenticated = (user && user.auth_token) || (userData && userData.auth_token);

  if(userData && userData.auth_token && !user.auth_token) {
    onUpdateUser(userData);
  }

  useEffect(() => {
    onGetCampusRoles();
  }, [onGetCampusRoles]);

  const RenderPage = ({children, all}) => {
    return (
        <Fragment>
          {all ? <Header /> : null}
          {children}
          {all ? <Footer /> : null}
        </Fragment>
    );
  };

  return (
    <Fragment>

      <Routes>
        <Route exact path="/"
               element={
                   <RenderPage all={true}>
                       <Home />
                   </RenderPage>
                }>
        </Route>
        <Route exact path="/about-us"
                  element={
                      <RenderPage all={true}>
                          <AboutUs />
                      </RenderPage>
                  }>
        </Route>
              <Route exact path="/support"
                  element={
                      <RenderPage all={true}>
                          <Support />
                      </RenderPage>
                  }>
        </Route>
        <Route exact path="/privacy-policy"
                  element={
                      <RenderPage all={true}>
                          <PrivacyPolicy />
                      </RenderPage>
                  }>
        </Route>
        <Route exact path="/contact-us"
                  element={
                      <RenderPage all={true}>
                          <ContactUs />
                      </RenderPage>
                  }>
        </Route>
              <Route exact path="/request-quote"
                  element={
                      <RenderPage all={true}>
                          <RequestQuote />
                      </RenderPage>
                  }>
        </Route>
              <Route exact path="/request-demo"
                  element={
                      <RenderPage all={true}>
                          <RequestDemo />
                      </RenderPage>
                  }>
        </Route>
        <Route exact path="/product-value"
                  element={
                      <RenderPage all={true}>
                          <ProductValue />
                      </RenderPage>
                  }>
        </Route>
        {isAuthenticated ?
          <Route exact path="/requests-list"
                      element={
                          <RenderPage all={false}>
                              <Records />
                          </RenderPage>
                      }>
          </Route> :
          null
        }
        
            <Route exact path="/requests"
              element={isAuthenticated ?                          
                      <Navigate to="/requests-list" /> :
                      <RenderPage all={false}>
                          <Login />
                      </RenderPage>
                  }></Route>
        }
              <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.main.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUser: user => dispatch(updateUser(user)),
    onGetCampusRoles: () => dispatch(getCampusRoles())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
