import axios from 'axios';


const CP_Api = axios.create({
	baseURL: `${process.env.REACT_APP_API}/api/`,
	headers: { 'Content-Type': 'application/json' }
});

const API = {
	/* Auth */
	login: authData => {
		return CP_Api.post('Login/Authenticate', authData);
	},
	getRecords: token => {
		return CP_Api.get('request/GetRecords', {
			headers: { 'Authorization': 'Bearer ' + token }
		});
	},
	setViewedRequests: (token, data) => {
		return CP_Api.post('request/SetViewed', data, {
			headers: { 'Authorization': 'Bearer ' + token }
		});
	},
	setContactedRequests: (token, data) => {
		return CP_Api.post('request/SetContacted', data, {
			headers: { 'Authorization': 'Bearer ' + token }
		});
	},
	getCampusRoles: () => {
		return CP_Api.get('request/GetCampusRoles');
	},
	sendSupportRequest: data => {
		return CP_Api.post('request/Support', data);
	},
	sendContactRequest: data => {
		return CP_Api.post('request/Contact', data);
	},
	sendQuoteRequest: data => {
		return CP_Api.post('request/Quote', data);
	},
	sendDemoRequest: data => {
		return CP_Api.post('request/Demo', data);
	},
	exportRecords: (token, date) => {
		let url = 'request/ExportRequests';
		if(date) {
			url += '/' + date;
		}
		return CP_Api.get(url, {
			headers: { 'Authorization': 'Bearer ' + token, responseType: 'blob' },
			responseType: 'blob'
		});
	}
};

export default API;
