import React from 'react';
import PropTypes from 'prop-types';

import './ButtonStyles.css';

function Button({title, active, onClick = () => {}, disabled = false, cls = ''}) {

	return (
		<button className={"cp-btn " + cls + (active ? " active" : "")} onClick={onClick} disabled={disabled}>{title}</button>
	);
}

Button.propTypes = {
	title: PropTypes.string,
	cls: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

export default Button;
