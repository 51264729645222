import React, { useState } from 'react';
import SEO from '../../services/SEO';
import ScrollAnimation from 'react-animate-on-scroll';
import videovalue from '../../assets/video/Product-Value.mp4';
import boy from '../../assets/images/boy.png';
import medicalCross from '../../assets/images/medical-cross.png';
import clock from '../../assets/images/clock.png';
import parentInCar from '../../assets/images/parent-in-car.png';
import './ProductValue.css';

const pageProps = {
    title: "Control Point | Discover the Control Point dismissal application value",
    description: "Control Point is a full-control Dismissal Program for school administrators, parents, teachers and educators | Manage students dismissal and lanes of traffic effectively | Most effective school dismissal process automation application available to the U.S. K-12 education | Social distancing against COVID-19 spreading in school during dismissal every day | Comprehensive student dismissal software for schools, teachers, and parents | Efficient afternoon dismissal solution for school districts | Safe student dismissal app for car rider lines | The control point dismissal",
    url: "https://thecontrolpoint.com",
    robots: "index, follow"
}

function ProductValue() {
   return (
        <>
            <SEO pageProps={pageProps} />
            <div className="page-wrapper">
         

               <section className="product-value-dismissal-section">
                    <div className="container">
                       <div className="row">
                            <div className="col-12">
                              
                                    <div className="afternoon-video-container">
                                        <ScrollAnimation className="video-right-circle-wrapper" animateIn='videoBounceInLeft' animateOnce offset={10}>
                                            <div className="video-left-circle"></div>
                                        </ScrollAnimation>
                                        <ScrollAnimation className="video-left-circle-wrapper" animateIn='bounceInRight' animateOnce offset={10}>
                                            <div className="video-right-circle">
                                                <span className="child"></span>
                                            </div>
                                        </ScrollAnimation>
                                        <div className="afternoon-video">
                                            <video id="background-video" loop autoPlay muted controls>
                                                <source src={videovalue} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
               </section>

               <section className="product-value-ads-section">
                   <div className="container">
                       <div className="row">
                           <div className="col-12">
                               <h2 className="purple">Discover the Control Point value</h2>
                           </div>
                           <div className="col-12">
                               <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
                                   <div className="ads-container">
                                       <img src={clock} alt="Clock" title="Clock" height="45" />
                                       <span className="orange big">50%&nbsp;</span>
                                       <span className="purple"> reduction in time spent In active student dismissal.</span>
                                   </div>
                               </ScrollAnimation>
                           </div>

                           <div className="col-12 flip-container">
                               <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={500}>
                                   <div className="ads-container">
                                       <img src={boy} alt="Boy Student Control Point" title="Boy Student Control Point" height="45" />
                                       <span className="orange big">18s&nbsp;</span>
                                       <span className="purple"> average time our schools take to dismiss each student.</span>
                                   </div>
                               </ScrollAnimation>
                           </div>

                           <div className="col-12 flip-container">
                               <ScrollAnimation animateIn='wobble' delay={1000}>
                                   <div className="ads-container">
                                       <img src={parentInCar} alt="Parent In Car Control Point" title="Parent In Car Control Point" height="45" />
                                       <span className="orange big">95%&nbsp;</span>
                                       <span className="purple"> parent satisfaction with student safety and reduced wait times.</span>
                                   </div>
                               </ScrollAnimation>
                           </div>

                           <div className="col-12">
                               <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutRight' delay={1500}>
                                   <div className="ads-container">
                                       <img src={medicalCross} alt="Medical Cross" title="Medical Cross" height="45" />
                                       <span className="orange big">100%&nbsp;</span>
                                       <span className="purple"> schools' ability to follow CDC guidelines for social distancing during dismissal.</span>
                                   </div>
                               </ScrollAnimation>
                           </div>

                       </div>
                   </div>
               </section>

            </div>
        </>
    );
}

export default ProductValue;
