import React, { Component } from 'react';
import SEO from '../../services/SEO';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseIncompletePhoneNumber } from 'libphonenumber-js'

import { sendContactRequest } from '../../store/actions';
import contactUsImg from '../../assets/images/contact_us_img.png';
import Input from '../../UI/Input/Input';
import CPselect from '../../UI/CPselect/CPselect';
import Textarea from '../../UI/Textarea/Textarea';
import Button from '../../UI/Button/Button';
import {CONTACT_VALIDATION} from '../../services/validation';
import utils from '../../services/utils';
import './ContactUsStyles.css';


const pageProps = {
    title: "Contact Us - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Contact Us to learn more about Control Point - full-control Dismissal Program for school administrators, parents, teachers and educators | Manage students dismissal and lanes of traffic effectively | Get more information about most effective school dismissal process automation application available to the U.S. K-12 education | Social distancing against COVID-19 spreading in school during dismissal every day | Comprehensive student dismissal software for schools, teachers, and parents | Efficient afternoon dismissal solution for school districts | Safe student dismissal app for car rider lines | The control point dismissal",
    url: "https://thecontrolpoint.com/contact-us",
    robots: "index, follow"
}
const INITIAL_STATE = {
    fields: {
        FullName: '',
        CampusRoleId: '',
        SchoolName: '',
        SchoolAddress: '',
        SchoolDistrict: '',
        Email: '',
        Phone: '',
        Comment: ''
    },
    fieldsError: {},
    fieldsTouched: {}
};

class ContactUs extends Component {
    state = {
        ...INITIAL_STATE
    };

    checkButtonStatus = fields => {
        const fieldsArray = Object.keys(fields);
        let isDisabled = false;
        for(let i=0; i< fieldsArray.length; i++) {
            const fieldName = fieldsArray[i];
            if(CONTACT_VALIDATION[fieldName] && CONTACT_VALIDATION[fieldName].required && !fields[fieldName]) {
                isDisabled = true;
            }
        }

        return isDisabled;
    };

    handleFieldChange = (e, fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fields = {...fields};
        const _fieldsTouched = {...fieldsTouched};
        let value = e.target.value;
        if(fieldName === 'CampusRoleId') {
            value = +value;
        }
        if(fieldName === 'Phone') {
            value = parseIncompletePhoneNumber(value);
        }
        _fieldsTouched[fieldName] = true;

        this.setState({fields: {..._fields, [fieldName]: value}, fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, {...fields, [fieldName]: value}, _fieldsTouched, fieldsError, null, CONTACT_VALIDATION, this);
    };

    handleBlur = (fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fieldsTouched = {...fieldsTouched};
        _fieldsTouched[fieldName] = true;

        this.setState({fieldsTouched: _fieldsTouched}, () => {
            utils.checkValidationErrors(fieldName, fields, _fieldsTouched, fieldsError, true, CONTACT_VALIDATION, this);
        });
    };

    handleSubmit = () => {
        const {fields} = this.state;

        this.props.onSendContactRequest(fields)
            .then(success => {
                if(success) {
                    this.setState({...INITIAL_STATE});
                }
            });
    };

  render() {
      const {fields, fieldsError} = this.state;
      const {campusRoles, sendingContactRequest} = this.props;
      const btnDisabled = Object.keys(fieldsError).length > 0 || this.checkButtonStatus(fields) || sendingContactRequest;

      return (
           <>
              <SEO pageProps={pageProps} />
              <div className="page-wrapper">
              <section className="contact-us-section">
                  <div className="container">
                      <div className="row">
                              <div className="col-md-6 col-sm-12">
                              <h1 className="purple">Let's get in touch!</h1>
                              <div className="contact-us-form">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'FullName')}
                                                 onBlur={() => this.handleBlur('FullName')}
                                                 value={fields.FullName}
                                                 error={fieldsError.FullName}
                                                 placeholder="Full Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <CPselect onChange={e => this.handleFieldChange(e, 'CampusRoleId')}
                                                  onBlur={() => this.handleBlur('CampusRoleId')}
                                                  options={campusRoles}
                                                  name="Campus Role"
                                                  value={fields.CampusRoleId}
                                                  error={fieldsError.CampusRoleId} />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolName')}
                                                 onBlur={() => this.handleBlur('SchoolName')}
                                                 value={fields.SchoolName}
                                                 error={fieldsError.SchoolName}
                                                 placeholder="School Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolAddress')}
                                                 onBlur={() => this.handleBlur('SchoolAddress')}
                                                 value={fields.SchoolAddress}
                                                 error={fieldsError.SchoolAddress}
                                                 placeholder="School Address" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolDistrict')}
                                                 onBlur={() => this.handleBlur('SchoolDistrict')}
                                                 value={fields.SchoolDistrict}
                                                 error={fieldsError.SchoolDistrict}
                                                 placeholder="School District" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="email"
                                                 onChange={e => this.handleFieldChange(e, 'Email')}
                                                 onBlur={() => this.handleBlur('Email')}
                                                 value={fields.Email}
                                                 error={fieldsError.Email}
                                                 placeholder="Email" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'Phone')}
                                                 onBlur={() => this.handleBlur('Phone')}
                                                 value={fields.Phone}
                                                 error={fieldsError.Phone}
                                                 placeholder="Phone" />
                                      </div>
                                  </div>
                                  <Textarea rows={5}
                                        onChange={e => this.handleFieldChange(e, 'Comment')}
                                        onBlur={() => this.handleBlur('Comment')}
                                        value={fields.Comment}
                                        error={fieldsError.Comment}
                                        placeholder="Comments ..."  />
                                  <Button title="Submit" active onClick={this.handleSubmit} disabled={btnDisabled} />
                              </div>
                          </div>

                          <div className="col-md-5 col-sm-12 offset-md-1 offset-sm-0">
                              <div className="contact-us-btns-container">
                                  <NavLink strict to="/request-quote" className="cp-btn-link">Request quote</NavLink>
                                  <br />
                                  <NavLink strict to="/request-demo" className="cp-btn-link">Request demo</NavLink>
                                  <br />
                                  <NavLink strict to="/support" className="cp-btn-link">Request support</NavLink>
                              </div>
                              <div className="contact-us-image-container">
                                      <img src={contactUsImg} alt="Contact Us" title="Contact Us" />
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              </div>
           </>
      );
  }
}

const mapStateToProps = state => {
    return {
        campusRoles: state.main.campusRoles,
        sendingContactRequest: state.main.sendingContactRequest
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendContactRequest: data => dispatch(sendContactRequest(data))
    };
};

ContactUs.propTypes = {
    campusRoles: PropTypes.array,
    sendingContactRequest: PropTypes.bool,
    onSendContactRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
