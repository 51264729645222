import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TimePickerStyles.css';

class TimePicker extends Component {
	state = {
		hours: '00',
		minutes: '00',
		showingTime: '',
		period: 'AM',
		isOpened: false
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = e => {
		if(!this.node.contains(e.target)) {
			this.setState({isOpened: false});
			if(this.props.fieldsTouched) {
				this.props.onBlur();
			}
		}
	};

	handleToggleDropdown = () => {
		this.setState(prevState => {
			return {isOpened: !prevState.isOpened}
		}, () => {
			if(this.state.isOpened) {
				this.handleSelect();
			}
		});
	};

	handleTogglePeriod = () => {
		this.setState(prevState => {
			return {period: (prevState.period === 'AM' ? 'PM' : 'AM')}
		}, () => {
			this.handleSelect();
		});
	};

	handlePlus = (v) => {
		const {hours, minutes} = this.state;
		if(v === 'hours') {
			let _hours = parseInt(hours);
			if(_hours < 9) {
				_hours = '0' + (_hours + 1);
			} else if(_hours < 11) {
				_hours += 1;
			} else {
				_hours = '00';
			}
			this.setState({hours: _hours}, () => {
				this.handleSelect();
			});
		} else if(v === 'minutes') {
			let _minutes = parseInt(minutes);
			if(_minutes < 5) {
				_minutes = '0' + (_minutes + 5);
			} else if(_minutes < 55) {
				_minutes += 5;
			} else {
				_minutes = '00';
			}
			this.setState({minutes: _minutes}, () => {
				this.handleSelect();
			});
		}
	};

	handleMinus = (v) => {
		const {hours, minutes} = this.state;
		if(v === 'hours') {
			let _hours = parseInt(hours);
			if(_hours === 0) {
				_hours = '11';
			} else {
				_hours = ((_hours > 10) ? '' : '0') + (_hours - 1);
			}
			this.setState({hours: _hours}, () => {
				this.handleSelect();
			});
		} else if(v === 'minutes')  {
			let _minutes = parseInt(minutes);
			if(_minutes === 0) {
				_minutes = '55';
			} else {
				_minutes = ((_minutes > 10) ? '' : '0') + (_minutes - 5);
			}
			this.setState({minutes: _minutes}, () => {
				this.handleSelect();
			});
		}
	};

	handleSelect = () => {
		const { hours, minutes, period } = this.state;
		const { onChange } = this.props;
		let _hours = '00';

		if(period === 'PM' ) {
			_hours = parseInt(hours) + 12;
		} else {
			_hours = hours;
		}

		this.setState({showingTime: (hours + ':' + minutes + ' ' + period)}, () => {
			onChange(_hours + ':' + minutes + ':00');
		});
	};

	render() {
		const { hours, minutes, showingTime, period, isOpened } = this.state;
		const {
			placeholder = 'Time',
			error = '',
			classes = ''
			} = this.props;

		return (
			<div className="cp-timepicker-container">
				<div className={"form-group cp-timepicker-wrapper " + classes} ref={node => this.node = node}>
					<div onClick={this.handleToggleDropdown}>
						<input type="text" className="form-control cp-timepicker-field" placeholder={placeholder} value={showingTime} disabled/>
						<span className="cp-timepicker-arrow"></span>
					</div>
					{isOpened ?
						<div className="cp-timepicker-dropdown">
							<div className="cp-timepicker-dropdown-wrapper">
								<div className="cp-timepicker-dropdown-controls hours">
									<div className="cp-timepicker-dropdown-btn-up" onClick={() => this.handlePlus('hours')}></div>
									<div className="cp-timepicker-dropdown-value">{hours}</div>
									<div className="cp-timepicker-dropdown-btn-down" onClick={() => this.handleMinus('hours')}></div>
								</div>
								<div className="cp-timepicker-dropdown-value">:</div>
								<div className="cp-timepicker-dropdown-controls minutes">
									<div className="cp-timepicker-dropdown-btn-up" onClick={() => this.handlePlus('minutes')}></div>
									<div className="cp-timepicker-dropdown-value">{minutes}</div>
									<div className="cp-timepicker-dropdown-btn-down" onClick={() => this.handleMinus('minutes')}></div>
								</div>
								<div className="cp-timepicker-period" onClick={this.handleTogglePeriod}>{period}</div>
							</div>
						</div> : null
					}
				</div>
				{error ? <span className="cp-timepicker-error">{error}</span> : null}
			</div>
		);
	}
}

TimePicker.propTypes = {
	placeholder: PropTypes.string,
	error: PropTypes.string,
	classes: PropTypes.string,
	fieldsTouched: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
};

export default TimePicker;