import React from 'react';

import './TestimonialStyles.css';


function Testimonial({colorScheme, notes, author}) {

	return (
		<div className="testimonial-container">
			<div className={"testimonial-wrapper " + colorScheme}>
				<p className="notes">{notes}</p>
				<p className="author">{author}</p>
			</div>
			<div className="circle-1"></div>
			<div className="circle-2"></div>
			<div className="circle-3"></div>
		</div>
	);
}

export default Testimonial;