import React, { useEffect, useState } from 'react';
import logo0 from '../../assets/images/cp-home-logo-0.png';
import logo1 from '../../assets/images/cp-home-logo-1.png';
import logoEmpty from '../../assets/images/cp-home-logo-empty.png';
import './LogoAnimationStyles.css';

const IMAGES = [logo0, logo1, logo0, logo1];

function LogoAnimation() {

	const [firstAnimate, handleChangeFirstAnimate] = useState(false);
	const [animate, handleChangeAnimate] = useState(false);
	const [logoImg, handleChangeLogoImg] = useState(IMAGES[0]);

	useEffect(() => {
		let isMounted = true;

		let i = 0;

		handleChangeFirstAnimate(true);
		setTimeout(() => {
			if(isMounted) {
				handleChangeAnimate(false);
				handleChangeFirstAnimate(false);
			}
		}, 6500);
		const logoAnimation = setInterval(() => {
			i = (i >= IMAGES.length - 1) ? 0 : (i + 1);
			handleChangeLogoImg(IMAGES[i]);
			handleChangeAnimate(true);
			setTimeout(() => {
				if(isMounted) {
					handleChangeAnimate(false);
				}
			}, 6500);
		}, 7000);

		return () => {
			isMounted = false;
			clearTimeout(logoAnimation);
		}
	}, []);

	return (
		<div className="logo-image">
			<div className={"logo-image-wrapper " + (animate ? " animate " : "") + (firstAnimate ? " firstAnimate " : "")}>
				<img src={logoImg} className={"logo " + (animate ? " animate" : "") + (firstAnimate ? " firstAnimate " : "")} alt="Control Point process illustration" title="Control Point process illustration" />
				<img src={logoEmpty} className="logo-empty" alt="Control Point process illustration empty" title="Control Point process illustration empty" />
			</div>
		</div>
	);
}

export default LogoAnimation;
