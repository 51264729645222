import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import cpLogo from '../../assets/images/cp-logo.png';
import './HeaderStyles.css';


function Header() {
	const [isMenuOpened, handleMobileMenuToggle] = useState(false);
	const ref = useRef(null);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			handleMobileMenuToggle(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (
		<nav className="navbar navbar-expand-lg cp-nav">
			<div className="container">
				<NavLink className="navbar-brand" strict to="/">
					<img src={cpLogo} alt="Control Point" title="Control Point" width="30" className="d-inline-block align-top" />
					Control Point
				</NavLink>
				<button className="navbar-toggler" type="button" ref={ref}  onClick={() => handleMobileMenuToggle(!isMenuOpened)}>
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent" style={isMenuOpened ? {display: 'block'} : {display: 'none'}}>
					<ul className="navbar-nav ml-auto mb-2 mb-lg-0">
						<li className="nav-item"><NavLink strict to="/" className="nav-link">Home</NavLink></li>
						<li className="nav-item"><NavLink strict to="/support" className="nav-link">Support</NavLink></li>
						<li className="nav-item"><NavLink strict to="/product-value" className="nav-link">Product Value</NavLink></li>
						<li className="nav-item"><NavLink strict to="/about-us" className="nav-link">About Us</NavLink></li>
						<li className="nav-item"><NavLink strict to="/privacy-policy" className="nav-link">Privacy Policy</NavLink></li>
						<li className="nav-item"><NavLink strict to="/request-demo" className="nav-link">Request Demo</NavLink></li>
						<li className="nav-item"><NavLink strict to="/contact-us" className="nav-link contact-us">Contact Us</NavLink></li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default Header;