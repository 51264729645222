import React from 'react';

import loaderImg from '../../assets/images/loader.gif';
import './LoaderStyles.css';

function Loader() {

	return (
		<div className="loader-container">
			<img src={loaderImg} width="88" alt="Loading..." title="Loading..." />
		</div>
	);
}

export default Loader;
