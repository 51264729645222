import React from 'react';
import PropTypes from 'prop-types';

import './TextareaStyles.css';

function Textarea({
		value = '',
		placeholder = '',
		rows = 3,
		onChange = () => {},
		onBlur = () => {},
		error = '',
		classes = ''
	}) {

	return (
		<div className="cp-textarea-container">
			<div className={"form-group cp-textarea-wrapper " + classes}>
				<textarea className="form-control cp-textarea" placeholder={placeholder} rows={rows} onChange={onChange} onBlur={onBlur} value={value}></textarea>
			</div>
			{error ? <span className="cp-textarea-error">{error}</span> : null}
		</div>
	);
}

Textarea.propTypes = {
	value: PropTypes.string,
	placeholder: PropTypes.string,
	rows: PropTypes.number,
	error: PropTypes.string,
	classes: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
};

export default Textarea;
