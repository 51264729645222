import React, { Component, Fragment, forwardRef } from 'react';
import SEO from '../../services/SEO';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getRecords, setViewedRequests, setContactedRequests, exportRecords } from '../../store/actions';
import Scrollbar from 'react-scrollbars-custom';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import utils from '../../services/utils';
import logo from '../../assets/images/login-logo.png';
import Loader from '../../UI/Loader/Loader';
import Button from '../../UI/Button/Button';
import './RecordsStyles.css';


const pageProps = {
    title: "Request List - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Control Point administration panel",
    url: "https://thecontrolpoint.com/requests-list",
    robots: "noindex, nofollow"
}

const CustomInput = forwardRef(({ onClick, value }, ref) => (
    <input
        onClick={onClick}
        ref={ref}
        value={value}
        type="text"
        readOnly={true}
        placeholder="Select date"
        />
));

const ref = React.createRef();
const calendarRef = React.createRef();

const EmptyListImg = () => (
    <div className="empty-list">
        <div className="empty-list-img-container">
            <div className="empty-list-img"></div>
        </div>
        <p>No records were found, select another day.</p>
    </div>
);

class Records extends Component {

    state = {
        activeTab: 'supportRequests',
        currentPage: 0,
        chunk: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        pageCount: 0,
        currentListQty: 0,
        contactingRequests: {},
        dateFilter: undefined,
        showNotViewed: false
    };

    componentDidMount(){
        this.props.onGetRecords();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {dateFilter} = this.state;

        if(Object.keys((nextProps && nextProps.records) || {}).length ) {
            const _records = utils.filterByDateAndViewed(nextProps.records, dateFilter);
            this.updatePageCount(_records);
        }
    }

    handleChangeTab = (activeTab, showNotViewed) => {
        const {records} = this.props;
        const {dateFilter} = this.state;
        const _records = utils.filterByDateAndViewed(records, dateFilter);

        this.setState({showNotViewed, activeTab, currentPage: 0, chunk: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], pageCount: 0,contactingRequests: {}}, () => {
            this.updatePageCount(_records);
        });
    };

    handleChangeDateFilter = (e) => {
        const {records} = this.props;
        const {activeTab} = this.state;
        this.setState({showNotViewed: false, dateFilter: e}, () => {
            let _activeTab = activeTab;
            const _records = utils.filterByDateAndViewed(records, e);
            this.updatePageCount(_records);

            if((_records[activeTab] || []).length === 0) {
                if((_records.supportRequests || []).length > 0) {
                    _activeTab = 'supportRequests';
                } else if((_records.demoRequests || []).length > 0) {
                    _activeTab = 'demoRequests';
                } else if((_records.quoteRequests || []).length > 0) {
                    _activeTab = 'quoteRequests';
                } else if((_records.contactRequests || []).length > 0) {
                    _activeTab = 'contactRequests';
                }
            }
            this.setState({activeTab: _activeTab});
        })
    };

    handleChangePage = page => {
        const _newPage = page.selected;
        const _chunk = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => {
            return (e + 10*_newPage);
        });
        this.setState({currentPage: _newPage, chunk: _chunk});
    };

    updatePageCount = (records) => {
        const {activeTab} = this.state;
        let _pageCount = 0;
        let _currentListQty = 0;

        const _sLength = (records.supportRequests || []).length;
        const _dLength = (records.demoRequests || []).length;
        const _qLength = (records.quoteRequests || []).length;
        const _cLength = (records.contactRequests || []).length;

        switch(activeTab) {
            case "supportRequests":
                _pageCount = Math.ceil(_sLength / 10);
                _currentListQty = _sLength;
                break;
            case "demoRequests":
                _pageCount = Math.ceil(_dLength / 10);
                _currentListQty = _dLength;
                break;
            case "quoteRequests":
                _pageCount = Math.ceil(_qLength / 10);
                _currentListQty = _qLength;
                break;
            case "contactRequests":
                _pageCount = Math.ceil(_cLength / 10);
                _currentListQty = _cLength;
                break;
            default:
                _pageCount = 0;
        }
        this.setState({pageCount: _pageCount, currentListQty: _currentListQty});
    };

    handleChangeCheckbox = (e, RequestID, RequestTypeID) => {
        const {contactingRequests} = this.state;
        const _contactingRequests = {...contactingRequests};
        if(e.target.checked) {
            _contactingRequests[RequestID] = RequestTypeID
        } else {
            delete _contactingRequests[RequestID];
        }
        this.setState({contactingRequests: _contactingRequests})
    };

    handleSendContacted = () => {
        const {contactingRequests, activeTab} = this.state;
        const dataToSend = Object.keys(contactingRequests).map(id => {
            return {RequestID: +id, RequestTypeID: +contactingRequests[id]};
        });
        this.props.onSetContactedRequests(dataToSend, activeTab).then(response => {
            if (response) {
                this.setState({contactingRequests: {}});
            }
        });
    };

    handleClickQty = (e, type) => {
        e.stopPropagation();
        this.setState({showNotViewed: true}, () => {
            const {records} = this.props;
            const {dateFilter} = this.state;

            this.handleChangeTab(type, true);

            setTimeout(() => {
                const _records = utils.filterByDateAndViewed(records, dateFilter, true);
                const dataToSend = _records[type].map(i => ({RequestID: +i.id, RequestTypeID: +i.requestTypeId}));
                this.props.onSetViewedRequests(dataToSend, type);
            },700);
        })
    };

    handleExportRecords = () => {
        const {dateFilter} = this.state;
        const _formattedDate = dateFilter ? moment(dateFilter).format('YYYY-MM-DD') : null;

        this.props.onExportRecords(_formattedDate).then((file) => {
            const blob = new Blob ([file], {type: 'application/octet-stream'}, "records" + (_formattedDate ? ("_" + _formattedDate) : "") + ".xlsx");
            const url = window.URL.createObjectURL (blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "records" + (_formattedDate ? ("_" + _formattedDate) : "") + ".xlsx";
            a.click();
            document.body.removeChild(a);
        });
    };

    handlePrintPDF = () => {
        const doc = new jsPDF({orientation: "landscape"});
        const {records, campusRoles} = this.props;
        const {dateFilter, showNotViewed} = this.state;
        const _records = utils.filterByDateAndViewed(records, dateFilter, showNotViewed);

        const _supportBody = _records.supportRequests.map(i => {
            const _cr = campusRoles.filter(c => c.id === i.campusRoleId) || [];
            const _contactDate = (i.contactDate || '').split('T')[0];
            const _date = (i.creationDate || '').split('T')[0];

            return [i.id, i.fullName, i.email, ((_cr && _cr[0] && _cr[0].name) || ''), i.phone, i.performedSteps, i.actionResults, _contactDate, _date];
        });
        const _demoBody = _records.demoRequests.map(i => {
            const _cr = campusRoles.filter(c => c.id === i.campusRoleId) || [];
            const _contactDate = (i.contactDate || '').split('T')[0];
            const _date = (i.creationDate || '').split('T')[0];

            return [i.id, i.fullName, i.email, ((_cr && _cr[0] && _cr[0].name) || ''), i.schoolName, i.schoolAddress, i.schoolDistrict, i.bestContactTime, i.phone, i.comment, _contactDate, _date];
        });
        const _quoteBody = _records.quoteRequests.map(i => {
            const _cr = campusRoles.filter(c => c.id === i.campusRoleId) || [];
            const _contactDate = (i.contactDate || '').split('T')[0];
            const _date = (i.creationDate || '').split('T')[0];

            return [i.id, i.fullName, i.email, ((_cr && _cr[0] && _cr[0].name) || ''), i.schoolName, i.schoolAddress, i.schoolDistrict, i.numberOfStudents, i.numberOfFaculty, i.phone, i.comment, _contactDate, _date];
        });
        const _contactBody = _records.contactRequests.map(i => {
            const _cr = campusRoles.filter(c => c.id === i.campusRoleId) || [];
            const _contactDate = (i.contactDate || '').split('T')[0];
            const _date = (i.creationDate || '').split('T')[0];

            return [i.id, i.fullName, i.email, ((_cr && _cr[0] && _cr[0].name) || ''), i.schoolName, i.schoolAddress, i.schoolDistrict, i.phone, i.comment, _contactDate, _date];
        });

        doc.text("Support Requests", 10, 10);
        doc.autoTable({
            headStyles: { fillColor: [110, 53, 174] },
            theme: 'grid',
            head: [['No.', 'Name', 'Email', 'Campus Role', 'Phone', 'What Steps', 'What happens', 'Contact Date', 'Date']],
            body: _supportBody,
            startY: 20
        });

        doc.text("Demo Requests", 10, doc.lastAutoTable ? (doc.lastAutoTable.finalY + 30) : 30);
        doc.autoTable({
            headStyles: { fillColor: [110, 53, 174] },
            theme: 'grid',
            head: [['No.', 'Name', 'Email', 'Campus Role', 'School Name', 'School Address', 'School District', 'Best Contact Time', 'Phone', 'Comment', 'Contact Date', 'Date']],
            body: _demoBody,
            startY: doc.lastAutoTable ? (doc.lastAutoTable.finalY + 40) : 40
        });

        doc.text("Quote Requests", 10, doc.lastAutoTable ? (doc.lastAutoTable.finalY + 30) : 30);
        doc.autoTable({
            headStyles: { fillColor: [110, 53, 174] },
            theme: 'grid',
            head: [['No.', 'Name', 'Email', 'Campus Role', 'School Name', 'School Address', 'School District', 'Number of Students', 'Number of Faculty/Staff', 'Phone', 'Comment', 'Contact Date', 'Date']],
            body: _quoteBody,
            startY: doc.lastAutoTable ? (doc.lastAutoTable.finalY + 40) : 40
        });

        doc.text("Contact Us Requests", 10, doc.lastAutoTable ? (doc.lastAutoTable.finalY + 30) : 30);
        doc.autoTable({
            headStyles: { fillColor: [110, 53, 174] },
            theme: 'grid',
            head: [['No.', 'Name', 'Email', 'Campus Role', 'School Name', 'School Address', 'School District', 'Phone', 'Comment', 'Contact Date', 'Date']],
            body: _contactBody,
            startY: doc.lastAutoTable ? (doc.lastAutoTable.finalY + 40) : 40
        });

        //doc.save('table.pdf');
        doc.output('dataurlnewwindow')
    };

    render() {
        const {records, loadingRecords, campusRoles, currentPage, exportingRecords} = this.props;
        const {activeTab, pageCount, currentListQty, chunk, contactingRequests, dateFilter, showNotViewed} = this.state;
        const _records = utils.filterByDateAndViewed(records, dateFilter, showNotViewed);

        let supportRequests = _records.supportRequests || [];
        let demoRequests = _records.demoRequests || [];
        let quoteRequests = _records.quoteRequests || [];
        let contactRequests = _records.contactRequests || [];

        const supportNotViewedQty = utils.calculateNotViewedQty(supportRequests, 'supportRequests', this.handleClickQty);
        const demoNotViewedQty = utils.calculateNotViewedQty(demoRequests, 'demoRequests', this.handleClickQty);
        const quoteNotViewedQty = utils.calculateNotViewedQty(quoteRequests, 'quoteRequests', this.handleClickQty);
        const contactNotViewedQty = utils.calculateNotViewedQty(contactRequests, 'contactRequests', this.handleClickQty);

        const notEmpty = (activeTab === 'supportRequests' && supportRequests.length) || (activeTab === 'demoRequests' && demoRequests.length) || (activeTab === 'quoteRequests' && quoteRequests.length) || (activeTab === 'contactRequests' && contactRequests.length);

        return (
             <>
                <SEO pageProps={pageProps} />
                <section className="requests-list-section">
                <NavLink strict to="/" className="login-logo-container">
                        <img src={logo} width="100" alt="Control Point" title="Control Point" />
                </NavLink>
                {loadingRecords ?
                    <Loader /> :
                    <Fragment>
                        <div className="rl-table-container">
                            <div className="rl-tabs-container">
                                <div className="rl-tabs">
                                    {supportRequests.length === 0 ? null :
                                        <div className={"rl-tab-item " + (activeTab === 'supportRequests' ? ' active ' : '')} onClick={() => this.handleChangeTab('supportRequests')}>Support{supportNotViewedQty}</div>
                                    }
                                    {demoRequests.length === 0 ? null :
                                        <div className={"rl-tab-item " + (activeTab === 'demoRequests' ? ' active ' : '')} onClick={() => this.handleChangeTab('demoRequests')}>Demo{demoNotViewedQty}</div>
                                    }
                                    {quoteRequests.length === 0 ? null :
                                        <div className={"rl-tab-item " + (activeTab === 'quoteRequests' ? ' active ' : '')} onClick={() => this.handleChangeTab('quoteRequests')}>Quote{quoteNotViewedQty}</div>
                                    }
                                    {contactRequests.length === 0 ? null :
                                        <div className={"rl-tab-item " + (activeTab === 'contactRequests' ? ' active ' : '')} onClick={() => this.handleChangeTab('contactRequests')}>Contact{contactNotViewedQty}</div>
                                    }



                                </div>
                                <div className="rl-tabs-btns">
                                    <div className="rl-tabs-filter-container">
                                        <DatePicker selected={dateFilter}
                                                    dateFormat="MMM dd, yyyy"
                                                    isClearable
                                                    customInput={<CustomInput ref={ref} />}
                                                    onChange={this.handleChangeDateFilter}
                                                    showPopperArrow={false}
                                                    popperModifiers={{
                                                        offset: {
                                                          enabled: true,
                                                          offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                          enabled: true,
                                                          escapeWithReference: false,
                                                          boundariesElement: "viewport"
                                                        }
                                                    }}
                                                    ref={calendarRef} />
                                    </div>
                                    <Button cls="tab-btn" title="Print" active onClick={this.handlePrintPDF} disabled={!notEmpty} />
                                    <Button cls="tab-btn" title="Export" active onClick={this.handleExportRecords} disabled={exportingRecords || !notEmpty} />
                                </div>
                            </div>
                            <div className={"rl-table-wrapper " + (notEmpty ? "" : " empty")}>
                                {activeTab === 'supportRequests' ?
                                    <Scrollbar noScrollY
                                               style={{ width: '100%', minHeight: 470 }}
                                               renderer={props => {
                                                    const { elementRef, ...restProps } = props;
                                                    return <div {...restProps} ref={elementRef} className="customScrollbarsHolder" />;
                                               }}
                                               trackXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsTrackX" />;
                                                    }
                                               }}
                                               thumbXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsThUmBX" />;
                                                    }
                                               }}
                                        >
                                        {supportRequests.length ?
                                            <table className="table table-borderless table-support">
                                                <thead>
                                                    {utils.renderSupportHead()}
                                                </thead>
                                                <tbody>
                                                    {utils.renderSupportRequests(supportRequests, campusRoles, chunk, contactingRequests, this.handleChangeCheckbox)}
                                                </tbody>
                                            </table> :
                                            <EmptyListImg />
                                        }
                                    </Scrollbar>
                                     : null
                                }

                                {activeTab === 'demoRequests' ?
                                    <Scrollbar noScrollY
                                               style={{ width: '100%', minHeight: 470 }}
                                               renderer={props => {
                                                    const { elementRef, ...restProps } = props;
                                                    return <div {...restProps} ref={elementRef} className="customScrollbarsHolder" />;
                                               }}
                                               trackXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsTrackX" />;
                                                    }
                                               }}
                                               thumbXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsThUmBX" />;
                                                    }
                                               }}
                                        >
                                        {demoRequests.length?
                                            <table className="table table-borderless table-demo">
                                                <thead>
                                                    {utils.renderDemoHead()}
                                                </thead>
                                                <tbody>
                                                    {utils.renderDemoRequests(demoRequests, campusRoles, chunk, contactingRequests, this.handleChangeCheckbox)}
                                                </tbody>
                                            </table> :
                                            <EmptyListImg />
                                        }
                                    </Scrollbar>
                                    : null
                                }

                                {activeTab === 'quoteRequests' ?
                                    <Scrollbar noScrollY
                                               style={{ width: '100%', minHeight: 470 }}
                                               renderer={props => {
                                                    const { elementRef, ...restProps } = props;
                                                    return <div {...restProps} ref={elementRef} className="customScrollbarsHolder" />;
                                               }}
                                               trackXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsTrackX" />;
                                                    }
                                               }}
                                               thumbXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsThUmBX" />;
                                                    }
                                               }}
                                        >
                                        {quoteRequests.length ?
                                            <table className="table table-borderless table-quote">
                                                <thead>
                                                    {utils.renderQuoteHead()}
                                                </thead>
                                                <tbody>
                                                    {utils.renderQuoteRequests(quoteRequests, campusRoles, chunk, contactingRequests, this.handleChangeCheckbox)}
                                                </tbody>
                                            </table> :
                                            <EmptyListImg />
                                        }
                                    </Scrollbar>
                                    : null
                                }

                                {activeTab === 'contactRequests' ?
                                    <Scrollbar noScrollY
                                               style={{ width: '100%', minHeight: 470 }}
                                               renderer={props => {
                                                    const { elementRef, ...restProps } = props;
                                                    return <div {...restProps} ref={elementRef} className="customScrollbarsHolder" />;
                                               }}
                                               trackXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsTrackX" />;
                                                    }
                                               }}
                                               thumbXProps={{
                                                    renderer: props => {
                                                      const { elementRef, ...restProps } = props;
                                                      return <div {...restProps} ref={elementRef} className="customScrollbarsThUmBX" />;
                                                    }
                                               }}
                                        >
                                        {contactRequests.length ?
                                            <table className="table table-borderless table-contact">
                                                <thead>
                                                    {utils.renderContactHead()}
                                                </thead>
                                                <tbody>
                                                    {utils.renderContactRequests(contactRequests, campusRoles, chunk, contactingRequests, this.handleChangeCheckbox)}
                                                </tbody>
                                            </table> :
                                            <EmptyListImg />
                                        }
                                    </Scrollbar>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="rl-bottom-panel">
                            {notEmpty ?
                                <Button cls="rl-bottom-btn" title="Contacted" active onClick={this.handleSendContacted} disabled={Object.keys(contactingRequests).length === 0} /> :
                                null
                            }
                            <div>
                                {pageCount ?
                                    <div className="cp-pagination-wrapper">
                                        <div className="cp-pagination-info">
                                            Showing <span>{chunk[0] + 1}</span> - <span>{(currentListQty < chunk[9] + 1 ? currentListQty : chunk[9] + 1)}</span> of <span>{currentListQty}</span>
                                        </div>
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'breakLabel'}
                                            forcePage={0}
                                            initialPage={currentPage}
                                            pageCount={pageCount ||20}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={4}
                                            onPageChange={this.handleChangePage}
                                            containerClassName={'cp-pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            />
                                    </div>: null
                                }
                            </div>
                        </div>
                    </Fragment>
                }
                </section>
             </>
        );
    }
}

const mapStateToProps = state => {
    return {
        records: state.main.records,
        loadingRecords: state.main.loadingRecords,
        campusRoles: state.main.campusRoles,
        exportingRecords: state.main.exportingRecords,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRecords: () => dispatch(getRecords()),
        onSetViewedRequests: (data, requestName) => dispatch(setViewedRequests(data, requestName)),
        onSetContactedRequests: (data, requestName) => dispatch(setContactedRequests(data, requestName)),
        onExportRecords: date => dispatch(exportRecords(date))
    };
};

Records.propTypes = {
    records: PropTypes.object,
    campusRoles: PropTypes.array,
    loadingRecords: PropTypes.bool,
    exportingRecords: PropTypes.bool,
    onGetRecords: PropTypes.func,
    onSetViewedRequests: PropTypes.func,
    onSetContactedRequests: PropTypes.func,
    onExportRecords: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Records);
