import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './InputQtyDropdownStyles.css';

class InputQtyDropdown extends Component {
	state = {
		isOpened: false,
		timerId: []
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = e => {
		if(!this.node.contains(e.target)) {
			this.setState({isOpened: false});
		}
	};

	handleChange = e => {
		const { max, min } = this.props;
		let _value = e.target.value;
		_value = _value.replace(/\D/g,'');
		_value = (_value <= min ) ? min : (_value >=  max ) ? max : _value;
		_value = (e.target.value === '') ? '' : parseInt(_value);

		this.props.onChange({target: {value: _value}});
	};

	handleToggleDropdown = () => {
		this.setState(prevState => {
			return {isOpened: !prevState.isOpened}
		});
	};

	handlePlus = () => {
		const { value, max, onChange } = this.props;
		const _value = (+value < max) ? (+value + 1) : value;
		onChange({target: {value: +_value}});
	};

	handleMinus = () => {
		const { value, min, onChange } = this.props;
		const _value = ((+value > min) ? (+value - 1) : value) || 0;
		onChange({target: {value: +_value}});
	};

	handleEvent = (event, act, eventType) => {
		const { timerId } = this.state;
		if (eventType && event.type === eventType) {
			let _timerId = setInterval(() => {
				if(act === 'plus') {
					this.handlePlus();
				} else if(act === 'minus') {
					this.handleMinus();
				}
			}, 200);
			this.setState(prevState => {
				const _arr = prevState.timerId;
				_arr.push(_timerId);
				return {timerId: _arr}
			});
		} else if(timerId.length) {
			for(let i=0; i<timerId.length; i++) {
				clearInterval(timerId[i]);
			}
			this.setState({timerId: []});
		}
	};

	render() {
		const { isOpened } = this.state;
		const {
			value = '',
			placeholder = '',
			type = 'text',
			onBlur = () => {},
			error = '',
			classes = '',
			min = 1000,
			max = 1000
			} = this.props;

		return (
			<div className="cp-qty-dropdown-container">
				<div className={"form-group cp-qty-dropdown-wrapper " + classes} ref={node => this.node = node}>
					<input type={type} className="form-control cp-qty-dropdown-field" placeholder={placeholder}
						   onChange={this.handleChange} onBlur={onBlur} value={value}/>
					<span className="cp-qty-dropdown-arrow"  onClick={this.handleToggleDropdown}></span>
					{isOpened ?
						<div className="cp-qty-dropdown">
							<div className="cp-qty-dropdown-controls">
								<div className={"cp-qty-dropdown-btn minus " + (value === min ? "disabled" : "")}
									 onClick={this.handleMinus}
									 onTouchStart={e => this.handleEvent(e, 'minus', 'touchstart')}
									 onTouchEnd={e => this.handleEvent(e, 'minus')}
									 onMouseDown={e => this.handleEvent(e, 'minus', 'mousedown')}
									 onMouseUp={e => this.handleEvent(e, 'minus')}>-</div>

								<div className="cp-qty-dropdown-value">{value || 0}</div>

								<div className={"cp-qty-dropdown-btn plus " + (value === max ? "disabled" : "")}
									 onClick={this.handlePlus}
									 onTouchStart={e => this.handleEvent(e, 'plus', 'touchstart')}
									 onTouchEnd={e => this.handleEvent(e, 'plus')}
									 onMouseDown={e => this.handleEvent(e, 'plus', 'mousedown')}
									 onMouseUp={e => this.handleEvent(e, 'plus')}>+</div>
							</div>
						</div> : null
					}
				</div>
				{error ? <span className="cp-qty-dropdown-error">{error}</span> : null}
			</div>
		);
	}
}

InputQtyDropdown.propTypes = {
	value: PropTypes.any,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	classes: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	onBlur: PropTypes.func
};

export default InputQtyDropdown;
