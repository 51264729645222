const EMAIL_REGEX = /^.+@[^.].*\.[a-z]{2,}$/;

export const LOGIN_VALIDATION = {
	UserName: {
		required: true
	},
	Password: {
		required: true
	}
};

export const SUPPORT_VALIDATION = {
	CampusRoleId: {
		required: true
	},
	FullName: {
		required: true
	},
	Email: {
		required: true,
		regexp: EMAIL_REGEX
	},
	Phone: {
		required: true
	},
	PerformedSteps: {
		required: true
	},
	ActionResults: {
		required: true
	}
};

export const CONTACT_VALIDATION = {
	FullName: {
		required: true
	},
	CampusRoleId: {
		required: true
	},
	SchoolName: {
		required: true
	},
	SchoolAddress: {
		required: true
	},
	SchoolDistrict: {
		required: true
	},
	Email: {
		required: true,
		regexp: EMAIL_REGEX
	},
	Phone: {
		required: true
	},
	Comment: {
		required: false
	}
};

export const QUOTE_VALIDATION = {
	FullName: {
		required: true
	},
	CampusRoleId: {
		required: true
	},
	SchoolName: {
		required: true
	},
	SchoolAddress: {
		required: true
	},
	SchoolDistrict: {
		required: true
	},
	Email: {
		required: true,
		regexp: EMAIL_REGEX
	},
	Phone: {
		required: true
	},
	NumberOfStudents: {
		required: true
	},
	NumberOfFaculty: {
		required: true
	},
	Comment: {
		required: false
	}
};

export const DEMO_VALIDATION = {
	FullName: {
		required: true
	},
	CampusRoleId: {
		required: true
	},
	SchoolName: {
		required: true
	},
	SchoolAddress: {
		required: true
	},
	SchoolDistrict: {
		required: true
	},
	Email: {
		required: true,
		regexp: EMAIL_REGEX
	},
	Phone: {
		required: true
	},
	BestContactDate: {
		required: true
	},
	BestContactTime: {
		required: true
	},
	Comment: {
		required: false
	}
};