import React from 'react';
import SEO from '../../services/SEO';
import file from '../../assets/files/Privacy_policy.pdf';
import privacyPolicyImg from '../../assets/images/privacy_policy_img.png';
import privacyPdf from '../../assets/images/pdf_icon.png';
import './PrivacyPolicyStyles.css';

const pageProps = {
    title: "Privacy Policy - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Data protection and security is our top priority! Learn more about Control Point privacy policy. Control Point is a full-control Dismissal Program for school administrators, parents, teachers and educators | Manage students dismissal and lanes of traffic effectively | Get more information about most effective school dismissal process automation application available to the U.S. K-12 education | Social distancing against COVID-19 spreading in school during dismissal every day | Comprehensive student dismissal software for schools, teachers, and parents | Efficient afternoon dismissal solution for school districts | Safe student dismissal app for car rider lines | The control point dismissal",
    url: "https://thecontrolpoint.com/privacy-policy",
    robots: "index, follow"
}
function PrivacyPolicy() {

    return (
       <>
            <SEO pageProps={pageProps} />
            <div className="page-wrapper">
          <section className="privacy-section">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6 col-sm-12">
                          <h1 className="purple">Privacy Policy</h1>
                          <p className="privacy-version purple">Version Effective Date: June 30, 2020</p>
                              <a className="privacy-download-container" title="Privacy Policy.pdf" download='Privacy Policy.pdf' href={file}>
                              <h2 className="purple">Download:</h2>
                              <div className="privacy-download-wrapper">
                                        <img src={privacyPdf} alt="Pdf" title="Pdf" />
                                  <label className="purple">Privacy Policy.pdf</label>
                              </div>
                          </a>
                      </div>

                      <div className="col-md-5 col-sm-12 offset-md-1 offset-sm-0">
                          <div className="privacy-image-container">
                                <img src={privacyPolicyImg} alt="Control Point Privacy Policy" title="Control Point Privacy Policy" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
            </div>
       </>
  );
}

export default PrivacyPolicy;
