import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,

	UPDATE_USER_DATA,

	GET_RECORDS_START,
	GET_RECORDS_SUCCESS,
	GET_RECORDS_FAIL,

	SET_VIEWED_REQUEST_START,
	SET_VIEWED_REQUEST_SUCCESS,
	SET_VIEWED_REQUEST_FAIL,

	SET_CONTACTED_REQUEST_START,
	SET_CONTACTED_REQUEST_SUCCESS,
	SET_CONTACTED_REQUEST_FAIL,

	GET_CAMPUS_ROLES_START,
	GET_CAMPUS_ROLES_SUCCESS,
	GET_CAMPUS_ROLES_FAIL,

	SEND_SUPPORT_REQUEST_START,
	SEND_SUPPORT_REQUEST_SUCCESS,
	SEND_SUPPORT_REQUEST_FAIL,

	SEND_CONTACT_REQUEST_START,
	SEND_CONTACT_REQUEST_SUCCESS,
	SEND_CONTACT_REQUEST_FAIL,

	SEND_QUOTE_REQUEST_START,
	SEND_QUOTE_REQUEST_SUCCESS,
	SEND_QUOTE_REQUEST_FAIL,

	SEND_DEMO_REQUEST_START,
	SEND_DEMO_REQUEST_SUCCESS,
	SEND_DEMO_REQUEST_FAIL,

	EXPORT_RECORDS_START,
	EXPORT_RECORDS_SUCCESS,
	EXPORT_RECORDS_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	user: {},
	loggingIn: false,
	campusRoles: [],
	loading: false,
	sendingSupportRequest: false,
	sendingContactRequest: false,
	sendingQuoteRequest: false,
	sendingDemoRequest: false,
	records: {},
	loadingRecords: false,
	setViewedRequests: false,
	setContactedRequests: false,
	file: null,
	exportingRecords: false

};

/* auth */
const loginStart = (state) => {
	return utils.updateObject(state, {
		loggingIn: true
	});
};

const loginSuccess = (state, action) => {
	return utils.updateObject(state, {
		user: action.user,
		loggingIn: false
	});
};

const loginFail = (state) => {
	return utils.updateObject(state, {
		user: {},
		loggingIn: false
	});
};

const updateUserData = (state, action) => {
	return utils.updateObject(state, {
		user: action.user
	});
};
/* auth end */

/* Get Records */
const getRecordsStart = (state) => {
	return utils.updateObject(state, {
		loadingRecords: true
	});
};

const getRecordsSuccess = (state, action) => {
	return utils.updateObject(state, {
		records: action.records,
		loadingRecords: false
	});
};

const getRecordsFail = (state) => {
	return utils.updateObject(state, {
		records: [],
		loadingRecords: false
	});
};
/* Get Records end */

/* Set Viewed Requests */
const setViewedRequestsStart = (state) => {
	return utils.updateObject(state, {
		setViewedRequests: true
	});
};

const setViewedRequestsSuccess = (state, action) => {
	return utils.updateObject(state, {
		setViewedRequests: false
	});
};

const setViewedRequestsFail = (state) => {
	return utils.updateObject(state, {
		setViewedRequests: false
	});
};
/* Set Viewed Requests end */

/* Set Contacted Requests */
const setContactedRequestsStart = (state) => {
	return utils.updateObject(state, {
		setContactedRequests: true
	});
};

const setContactedRequestsSuccess = (state, action) => {
	return utils.updateObject(state, {
		setContactedRequests: false
	});
};

const setContactedRequestsFail = (state) => {
	return utils.updateObject(state, {
		setContactedRequests: false
	});
};
/* Set Contacted Requests end */

/* Get Campus Roles */
const getCampusRolesStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getCampusRolesSuccess = (state, action) => {
	return utils.updateObject(state, {
		campusRoles: action.campusRoles,
		loading: false
	});
};

const getCampusRolesFail = (state) => {
	return utils.updateObject(state, {
		campusRoles: [],
		loading: false
	});
};
/* Get Campus Roles end */

/* Send Support Request */
const sendSupportRequestStart = (state) => {
	return utils.updateObject(state, {
		sendingSupportRequest: true
	});
};

const sendSupportRequestSuccess = (state, action) => {
	return utils.updateObject(state, {
		sendingSupportRequest: false
	});
};

const sendSupportRequestFail = (state) => {
	return utils.updateObject(state, {
		sendingSupportRequest: false
	});
};
/* Send Support Request end */

/* Send Contact Request */
const sendContactRequestStart = (state) => {
	return utils.updateObject(state, {
		sendingContactRequest: true
	});
};

const sendContactRequestSuccess = (state, action) => {
	return utils.updateObject(state, {
		sendingContactRequest: false
	});
};

const sendContactRequestFail = (state) => {
	return utils.updateObject(state, {
		sendingContactRequest: false
	});
};
/* Send Contact Request end */

/* Send Quote Request */
const sendQuoteRequestStart = (state) => {
	return utils.updateObject(state, {
		sendingQuoteRequest: true
	});
};

const sendQuoteRequestSuccess = (state, action) => {
	return utils.updateObject(state, {
		sendingQuoteRequest: false
	});
};

const sendQuoteRequestFail = (state) => {
	return utils.updateObject(state, {
		sendingQuoteRequest: false
	});
};
/* Send Quote Request end */

/* Send Demo Request */
const sendDemoRequestStart = (state) => {
	return utils.updateObject(state, {
		sendingDemoRequest: true
	});
};

const sendDemoRequestSuccess = (state, action) => {
	return utils.updateObject(state, {
		sendingDemoRequest: false
	});
};

const sendDemoRequestFail = (state) => {
	return utils.updateObject(state, {
		sendingDemoRequest: false
	});
};
/* Send Demo Request end */

/* Export Records */
const exportRecordsStart = (state) => {
	return utils.updateObject(state, {
		exportingRecords: true
	});
};

const exportRecordsSuccess = (state, action) => {
	return utils.updateObject(state, {
		file: action.file,
		exportingRecords: false
	});
};

const exportRecordsFail = (state) => {
	return utils.updateObject(state, {
		file: null,
		exportingRecords: false
	});
};
/* Export Records end */

const mainReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case LOGIN_START: return loginStart(state, action);
	case LOGIN_SUCCESS: return loginSuccess(state, action);
	case LOGIN_FAIL: return loginFail(state, action);

	case UPDATE_USER_DATA: return updateUserData(state, action);

	case GET_RECORDS_START: return getRecordsStart(state, action);
	case GET_RECORDS_SUCCESS: return getRecordsSuccess(state, action);
	case GET_RECORDS_FAIL: return getRecordsFail(state, action);

	case SET_VIEWED_REQUEST_START: return setViewedRequestsStart(state, action);
	case SET_VIEWED_REQUEST_SUCCESS: return setViewedRequestsSuccess(state, action);
	case SET_VIEWED_REQUEST_FAIL: return setViewedRequestsFail(state, action);

	case SET_CONTACTED_REQUEST_START: return setContactedRequestsStart(state, action);
	case SET_CONTACTED_REQUEST_SUCCESS: return setContactedRequestsSuccess(state, action);
	case SET_CONTACTED_REQUEST_FAIL: return setContactedRequestsFail(state, action);

	case GET_CAMPUS_ROLES_START: return getCampusRolesStart(state, action);
	case GET_CAMPUS_ROLES_SUCCESS: return getCampusRolesSuccess(state, action);
	case GET_CAMPUS_ROLES_FAIL: return getCampusRolesFail(state, action);

	case SEND_SUPPORT_REQUEST_START: return sendSupportRequestStart(state, action);
	case SEND_SUPPORT_REQUEST_SUCCESS: return sendSupportRequestSuccess(state, action);
	case SEND_SUPPORT_REQUEST_FAIL: return sendSupportRequestFail(state, action);

	case SEND_CONTACT_REQUEST_START: return sendContactRequestStart(state, action);
	case SEND_CONTACT_REQUEST_SUCCESS: return sendContactRequestSuccess(state, action);
	case SEND_CONTACT_REQUEST_FAIL: return sendContactRequestFail(state, action);

	case SEND_QUOTE_REQUEST_START: return sendQuoteRequestStart(state, action);
	case SEND_QUOTE_REQUEST_SUCCESS: return sendQuoteRequestSuccess(state, action);
	case SEND_QUOTE_REQUEST_FAIL: return sendQuoteRequestFail(state, action);

	case SEND_DEMO_REQUEST_START: return sendDemoRequestStart(state, action);
	case SEND_DEMO_REQUEST_SUCCESS: return sendDemoRequestSuccess(state, action);
	case SEND_DEMO_REQUEST_FAIL: return sendDemoRequestFail(state, action);

	case EXPORT_RECORDS_START: return exportRecordsStart(state, action);
	case EXPORT_RECORDS_SUCCESS: return exportRecordsSuccess(state, action);
	case EXPORT_RECORDS_FAIL: return exportRecordsFail(state, action);
	default:
		return state;
	}
};

export default mainReducer;